@import 'src/assets/scss/variable.scss';

.events-page-main-container {
  .events-container {
    height: 100%;
    .events-grid-container {
      height: 100%;
      display: flex;
      justify-content: space-between;
      @media (max-width: 1279px) {
        display: block;
      }

      // EVENT LEFT SIDE
      .events-left {
        position: relative;
        width: 36.548%;
        min-height: 863px;
        height: 100%;
        background: $blockColor;
        border: $blockBorder;
        border-radius: $bRadius;
        @media (max-width: 1279px) {
          min-height: auto;
          width: 100%;
        }
      }

      //   EVENT RIGHT SIDE
      .events-right {
        width: 63.452%;
        min-height: 863px;
        height: 100%;
        padding-left: 16.99px;
        @media (max-width: 1279px) {
          min-height: auto;
          width: 100%;
          margin-top: 18px;
          padding-left: 0;
        }
        .right-event-content {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
.MuiSelect-icon {
  color: $color-white !important;
}

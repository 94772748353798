@import 'src/assets/scss/variable.scss';

.user-organisation{

    background-color: $color-light-black;
    border: $blockBorder;
    padding: 18px;
    .user-profile-sec{
        display: flex;
        .user-profile{
            width: 115px;
            height: 115px;
        }
        .user-details{
            align-self: center;
            padding-left: 18px;
            .user-wildcard{
                font-family: $authHeaderFont;
                font-size: 16px;
                line-height: 20px;
                color: $color-blue;
            }
            .username-sec{
                font-family: $authHeaderFont;
                font-size: 28.45px;
                line-height: 28.45px;
                color:$color-white;
                cursor: pointer;
                word-break: break-word;
            }
            .member-since{
                font-family: $authHeaderFont;
                font-size: 12px;
                line-height: 15px;
                color: $color-pearl;
            }
        }
    }
    .address-block{
        padding: 19px 0 27px;
    }
        .address-title{
            font-family: $authHeaderFont;
            font-size: 16px;
            line-height: 20px;
            color: $color-white;
        }
        .address-text{
            font-family: $primaryFont;
            font-size: 16px;
            line-height: 20px;
            color: $color-white;
            word-break: break-word;
        }
        .edit-Organisation-btn-sec{
            padding-top: 18px;
            .edit-Organisation-btn{
                background-color: $color-blue;
                font-family: $primaryFont;
                font-size: 12px;
                line-height: 18px;
                color: $color-white;
                text-transform: capitalize;
            }
            .edit-Organisation-btn:hover{
                background-color: $color-blue;
            }
        }

}

@import 'src/assets/scss/variable.scss';

.fight-ticket-box {
  background-color: $blockColor;
  border: $blockBorder;
  border-radius: $bRadius;
  padding: 18px;
  min-height: 863px;
  height: 100%;
  .add-ticket-btn{
    background-color: $color-blue;
    color: $color-white;
    font-size: 12px;
    line-height: 18px;
    font-family: $primaryFont;
    text-transform: capitalize;
    &:hover {
      background-color: $color-blue;
      color: $color-white;
    }
  }
  .MuiIconButton-root {
    padding: 0;
  }
  .ticket-title-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .ticket-title {
      font-size: 37.92px;
      line-height: 47px;
      font-weight: 400;
      font-family: $authHeaderFont;
      color: $color-white;
    }
  }

  .ticket-form-container {
    margin-top: 26px;
  }
  .delete-ticket-box {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 27px;
    padding-bottom: 11.1px;
    border-bottom: $blockBorder;
  }
  .event-bg-sec{
    width: 100%;
    padding: 20px 12px;
    background:$color-light-black;
    .event-bg-grid{
      display: flex;
      justify-content: space-between;
      .date-bg-text{
        color: $color-pearl;
        font-size: 16px;
        line-height: 21px;
        font-family: $primaryFont;
        text-transform: uppercase;
      }
      .delete-date{
        padding: 0 5px;
      }
    }
  }
  .ticket-min-max-text{
    color: $color-pearl;
    font-size: 12px;
    line-height: 18px;
    font-family: $primaryFont;
  }
  .admission-text{
    color: $color-white;
    font-size: 16px;
    line-height: 21px;
    font-family: $primaryFont;
  }


}
.text-right{
  text-align: right;
}
.MuiListItem-root{
  padding-top: 0;
  padding-bottom: 0;
  .MuiListItemIcon-root{
    min-width:25px;
  }
}
.pb-0{
  padding-bottom: 0 !important;
}

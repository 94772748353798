@import 'src/assets/scss/variable.scss';

.bcdp-popover {
  .MuiPopover-paper {
    height: 300px;
    width: 250px;
    background: $blockColor;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll;
  }
  .MuiPopover-paper::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
  .checkmark-dropdown {
    color: $color-white;
    .MuiListItem-gutters {
      padding: 0 16px;
      cursor: pointer;
    }
    .MuiCheckbox-root {
      padding: 0 9px;
    }
    .MuiListItem-gutters {
      svg {
        color: $color-white;
      }
    }
    .MuiListItemIcon-root {
      min-width: unset;
    }
  }
}
.filter-button {
  background-color: transparent !important;
  width: unset !important;
  color: $color-pearl !important;
  text-transform: unset !important;
  .circle-blue {
    width: 21px;
    height: 21px;
    font-size: 12px !important;
    line-height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $color-blue;
    color: $color-white;
  }
}
.p-10 {
  padding: 10px;
}
.b-0 {
  border: 0;
}
.toggle-up,
.toggle-down {
  transition: all 0.4s ease;
}
.toggle-down {
  transform: rotateZ(180deg);
}

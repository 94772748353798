@import 'src/assets/scss/variable.scss';

.event-admin-sec{
    background-color: $blockColor;
    border: $blockBorder;
    border-radius: $bRadius;
    padding: 18px;
    min-height: 863px;
    height: 100%;
    .admins-title{
        font-size: 37.92px;
        line-height: 47px;
        font-weight: 400;
        font-family: $authHeaderFont;
        color: $color-white;
    }
    .admins-sub-title{
        padding-top: 29px;
        font-size: 21.33px;
        line-height: 26px;
    }
    .admin-title-text{
        font-size: 12px;
        line-height: 18px;
        font-family: $primaryFont;
        color: $color-spanish-gray;
    }
    .search-field-box {
        // position: relative;
        padding:18px 0;
        background: #171717;
        border-radius: 3px;
        border: 0;
    }
    .filter-box {
        background-color: $blockColor;
        border: $blockBorder;
        border-radius: $bRadius;
        padding: 10px;
        margin: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media (max-width: 599px) {
          padding: 12px;
        }
        .filter-title {
          font-size: 16px;
          line-height: 20px;
          font-family: $authHeaderFont;
          color: $color-white;
        }
        .filter-btn {
          background: $color-blue;
          color: $color-white;
          font-size: 12px;
          text-transform: unset;
          padding: 5px 15px;
          margin-left: 9px;
          @media (max-width: 480px) {
            margin-bottom: 15px;
          }
        }
        .filter-tags {
          display: flex;
          align-items: center;
          width: 75%;
          .filter-options {
            @media (max-width: 480px) {
              padding-left: 15px;
            }
          }

        }
    }
    .user-box{
        .user-name-title{
            font-size: 14px;
            line-height: 19px;
            font-weight: 400;
            font-family: $primaryFont;
            color: $color-spanish-gray;
            padding: 7px 9px;
            background: $color-msg-left;
            border-radius: 3px;
            margin: 9px 0;
            text-transform: uppercase;
        }
        .users-data{
            display: flex;
            justify-content: space-between;
            padding: 10px;
            border-bottom: $blockBorder;
            .user-data-sec{
                display: flex;
                .user-profile{
                    width: 30px;
                    height: 30px;
                }
               .user-name{
                font-size: 16px;
                line-height: 21px;
                font-family: $primaryFont;
                color: $color-white;
                padding-left: 18px;
                align-self: center;
                }

            }
            .user-delete-icon{
                padding: 0;
            }
        }
        .selected-user-sec{
          max-height: 200px;
          overflow-x: hidden;
          overflow-y: scroll;
          &::-webkit-scrollbar {
            width: 6px;
          }
          &::-webkit-scrollbar-track {
            background-color: $color-black-very-light;
            border-radius: $bRadius;
          }
          &::-webkit-scrollbar-thumb {
            background: $color-blue;
            border-radius: $bRadius;
          }
        }
    }
    .event-user-sec{
      position: relative;
    }
    .event-community-sec{
      position: relative;
      .search-adminlist-sec {
        top: 140px;
      }
    }
    .event-org-sec{
      position: relative;
      .search-adminlist-sec {
        top: 160px;
      }
    }
}
.search-dropdown{
  cursor: pointer;
}
.search-adminlist-sec{
  position: absolute;
  width: 100%;
  z-index: 9999;
  background-color: $color-light-black;
  top: 120px;
  max-height: 300px;
  overflow-x: hidden;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background-color: $color-black-very-light;
    border-radius: $bRadius;
  }
  &::-webkit-scrollbar-thumb {
    background: $color-blue;
    border-radius: $bRadius;
  }
  .search-admin-list{
    display: flex;
    justify-content: space-between;
    border: $blockBorder;
    align-items: center;
    padding: 9px 12px;
    .search-admin-left{
      display: flex;
      .search-profile{
        width: 60px;
        height: 60px;
      }
      .search-profile-sec{
        align-self: center;
        margin-left: 10px;
          .search-user-name{
            font-size: 18px;
            line-height: 18px;
            margin-bottom: 4px;
            font-family: $authHeaderFont;
            color: $color-white;
            text-align: left;
            word-break: break-word;
            cursor: pointer;
          }
          .search-user-data{
            font-family:$authHeaderFont;
            font-size: 12px;
            color: $color-pearl;
          }
      }
    }
    .search-admin-right{
      .gray-background {
        background: $color-gray-dark !important;
      }
      .member-btn {
        color: $color-white;
        background: $color-blue;
        font-size: 12px;
        text-transform: unset;
        font-weight: 300;
        padding: 5px 12px;
      }
    }
  }
}

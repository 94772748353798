@import 'src/assets/scss/variable.scss';

.fight-card-box {
  background-color: $blockColor;
  border: $blockBorder;
  border-radius: $bRadius;
  padding: 18px;
  min-height: 863px;
  height: 100%;

  .fight-card-title {
    font-size: 37.92px;
    line-height: 47px;
    font-weight: 400;
    font-family: $authHeaderFont;
    color: $color-white;
    margin-bottom: 27px;
  }

  .main-under-tb {
    display: flex;
    .card-btn {
      min-width: none;
      padding: 10px 18px;
      font-size: 18px;
      line-height: 22px;
      font-weight: 400;
      font-family: $authHeaderFont;
      color: #e5e5e5;
      text-transform: capitalize;
      margin-right: 9px;
    }
    .active {
      background-color: $color-blue;
      color: $color-white;
    }
  }

  .vs-frms {
    padding-top: 21px;
    .add-vs {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .MuiIconButton-root {
        padding: 0;
      }
    }

    .fight-form-box {
      margin-top: 18px;
      margin-bottom: 27px;
      .athelete-vs-grid {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 4.5px !important;
        padding-bottom: 4.5px !important;
        p {
          font-size: 21.33px;
          line-height: 26px;
          font-weight: 400;
          font-family: $authHeaderFont;
          color: $color-white;
        }
      }

      // DELETE FIGHT FORM
      .delete-fgtbtn-bx {
        padding-bottom: 7px;
        margin-top: 16px;
        margin-bottom: -12px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        border-bottom: $blockBorder;
        .MuiIconButton-root {
          padding: 0;
        }
      }
    }
  }
}

@import 'src/assets/scss/variable.scss';

.my-events-box {
  .MuiGrid-spacing-xs-3 {
    margin: -8px !important;
  }
  .MuiGrid-item {
    padding: 8px !important;
  }
}

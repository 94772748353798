@import 'src/assets/scss/variable.scss';


.created-evets-sec{
  background-color: $blockColor;
  border: $blockBorder;
  border-radius: $bRadius;
  padding: 18px;
  min-height: 863px;
  height: 100%;
  .mt-27{
    margin-top: 27px;
  }
  .mb-18{
    margin-bottom: 18px;
  }
  .p-0{
    padding: 0;
  }
  .actions-header {
      .MuiTab-wrapper {
        color: $color-white;
      }
      .MuiTab-textColorInherit {
        opacity: 1;
      }
  }
  .you-have-created-sec{
    .search-field-box {
      padding: 0;
      border: 0;
    }
    .filter-box {
      background-color: $blockColor;
      border: $blockBorder;
      border-radius: $bRadius;
      padding:7px 16px;
      margin: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 10px 0px;
      @media (max-width: 599px) {
        padding: 7px 16px;
      }
      .filter-title {
        font-size: 16px;
        line-height: 20px;
        font-family: $authHeaderFont;
        color: $color-white;
      }
      .filter-btn {
        background: $color-blue;
        color: $color-white;
        font-size: 12px;
        text-transform: unset;
        padding: 5px 15px;
        margin-left: 9px;
        @media (max-width: 480px) {
          margin-bottom: 15px;
        }
      }
      .filter-tags {
        display: flex;
        align-items: center;
        width: 75%;
        .filter-options {
          @media (max-width: 480px) {
            padding-left: 15px;
          }
        }

      }
    }
  }
}

.no-data-text {
  margin-top: 50px;
  margin-bottom: 18px;
  width: 100%;
  text-align: center;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: #3d3e3f;
}




.discover-event-box {
  .MuiGrid-spacing-xs-3 {
    margin: -8px !important;
  }
  .MuiGrid-item {
    padding: 8px !important;
  }
}

@import 'src/assets/scss/variable.scss';

.newsfeed-right {
  color: $color-white;
  .post-details-main {
    margin-top: 9px;
    background-color: #171717;
    border: 1px solid #2e353a;
    border-radius: 3px;
    .p-16 {
      padding: 16px;
    }
    .post-banner {
      .MuiAvatar-root {
        width: 100%;
        border-radius: unset;
        height: 200px;
      }
    }
    .post-image {
      display: flex;
      align-items: flex-end;
      margin-top: -30px;
      margin-left: 18px;
      .MuiAvatar-root {
        height: 70px;
        width: 70px;
      }
      .post-name {
        font-size: 22px;
        text-transform: uppercase;
        color: $color-white;
        font-family: Staatliches, Alumni Sans, sans-serif;
        margin-left: 14px;
      }
    }
    .post-content {
      .post-description {
        font-size: 14px;
        line-height: 20px;
      }
    }
    .post-other-data {
      margin-top: 18px;
      .leave-btn {
        background: $color-white;
        font-family: Staatliches, Alumni Sans, sans-serif;
        font-size: 18px;
      }
      .post-description {
        font-size: 16px;
        text-transform: uppercase;
        font-family: Staatliches, Alumni Sans, sans-serif;
        .color-blue {
          color: $color-blue;
        }
      }
    }
  }
}

@import 'src/assets/scss/variable.scss';

.community-list-block {
  margin-top: 9px;
  background-color: $blockColor;
  border-radius: $bRadius;
  border: $blockBorder;

  .com-list-head-box {
    display: flex;
    justify-content: space-between;
    margin-bottom: 18px;
    padding: 18px 18px 0 18px;
    @media (max-width: 599px) {
      display: block;
      padding: 12px 12px 0 12px;
    }
    .left-about {
      display: flex;
      .MuiAvatar-root {
        width: 72px;
        height: 72px;
        margin-right: 18px;
        @media (max-width: 599px) {
          width: 40px;
          height: 40px;
          margin-right: 14px;
        }
      }
      .com-name-created {
        .com-name {
          font-size: 28.45px;
          line-height: 36px;
          font-family: $authHeaderFont;
          color: $color-white;
          display: flex;
          align-items: center;
          @media (max-width: 599px) {
            font-size: 18px;
            line-height: 24px;
          }
          cursor: pointer;
          svg {
            margin-left: 9.5px;
            width: 20%;
          }
        }
        .com-created {
          text-transform: uppercase;
          font-size: 16px;
          line-height: 20px;
          font-family: $authHeaderFont;
          color: $color-white;
          @media (max-width: 599px) {
            font-size: 14px;
            line-height: 20px;
          }
          span {
            color: $color-blue;
          }
        }
      }
    }

    .right-about {
      text-transform: uppercase;
      @media (max-width: 599px) {
        margin-top: 9px;
      }
      .com-members {
        text-align: right;
        font-size: 16px;
        line-height: 20px;
        font-family: $authHeaderFont;
        color: $color-white;
        @media (max-width: 599px) {
          font-size: 14px;
        }
        span {
          color: $color-blue;
        }

        @media (max-width: 599px) {
          text-align: left;
        }
      }

      .com-for-box {
        text-align: right;
        @media (max-width: 599px) {
          text-align: left;
        }
        .com-for-txt {
          font-size: 16px;
          line-height: 20px;
          font-family: $authHeaderFont;
          color: $color-white;
          @media (max-width: 599px) {
            font-size: 14px;
          }
        }

        .com-for-chips {
          // display: flex;
          display: block;
          @media (max-width: 599px) {
            margin-top: 4px;
          }
          .com-chip {
            font-size: 12px;
            line-height: 18px;
            padding-bottom: 1px;
            margin-left: 3px;
            height: 21px;
            background-color: $color-blue;
            font-family: $primaryFont;
            color: $color-white;
            margin-top: 5px;
            @media (max-width: 599px) {
              margin-left: 0;
              margin-right: 3px;
            }
          }
        }
      }
    }
  }

  .last-post-title {
    padding: 0px 18px;
    font-size: 16px;
    line-height: 21px;
    font-weight: 700;
    font-family: $primaryFont;
    color: $color-white;
    margin-bottom: 5px;
    @media (max-width: 599px) {
      padding: 0px 12px;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 0px;
    }
  }
  .com-last-post-by {
    padding: 0px 18px;
    font-size: 16px;
    line-height: 21px;
    font-weight: 400;
    font-family: $primaryFont;
    color: $color-white;
    margin-bottom: 5px;
    cursor: pointer;
    @media (max-width: 599px) {
      padding: 0px 12px;
      font-size: 14px;
      line-height: 20px;
    }
  }

  //   Community Last Post
  .com-last-post {
    padding: 18px 18px 0;
    display: flex;
    padding-bottom: 17px;
    border-bottom: $blockBorder;
    cursor: pointer;
    @media (max-width: 767px) {
      display: block;
      padding: 0px 12px;
    }

    .last-post-media {
      // width: 122px;
      width: 150px;
      height: 98px;
      border-radius: 3px;
      margin-right: 12px;
      display: flex;
      .doc-sec{
        border: $blockBorder;
      }
      .post-image-slider{
        width: 100%;
        display: flex;
        justify-content: space-around;
        .MuiAvatar-root{
          width: 100%;
          height: 98px;
        }
        .react-player{
          max-width: 100%;
          max-height: 98px;
          video{
            max-height: 98px;
          }
        }
        .message-doc-view {
          align-self: center;
          svg {
            width: 24px;
            height: 24px;
          }
          .doc-link {
            text-transform: uppercase;
            display: flex;
            color: $color-white;
            text-decoration: none;
          }
          .doc-name {
            margin: 0;
            padding-left: 5px;
          }
        }
      }

      @media (max-width: 767px) {
        width: 100%;
        height: 150px;
        margin-bottom: 9px;
      }
    }

    .last-point-text {
      font-size: 16px;
      line-height: 21px;
      font-weight: 400;
      font-family: $primaryFont;
      text-align: justify;
      color: $color-white;
      .wysiwyg-mention {
        color: $color-blue;
      }
      img {
        width: 100% !important ;
      }
      @media (max-width: 767px) {
        font-size: 12px;
        line-height: 16px;
        margin-bottom: 9px;
      }
    }
    .post-title {
      font-size: 16px;
      line-height: 21px;
      font-weight: 700;
      font-family: $primaryFont;
      color: $color-white;
      margin-bottom: 3px;
    }
  }

  //   Community Actions
  .com-actions {
    padding: 0px 18px;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    @media (max-width: 599px) {
      padding: 0px 12px;
    }
    .com-act-btn {
      font-size: 12px;
      line-height: 18px;
      font-weight: 400;
      font-family: $primaryFont;
      color: #b4babf;
      text-transform: capitalize;
    }
  }
}
.cr-popover {
  .MuiPopover-paper {
    // min-width: 150px;
    padding: 0 10px;
    font-family: $primaryFont;
    color: black;
    font-weight: 400;
    // cursor: pointer;
  }
  .MuiButton-text {
    text-transform: unset;
  }
}

.share-dailog-box {
  .MuiDialog-paper {
    max-width: 398px !important;
    padding: 17px !important;
  }
}

.remit-amount-input {

    .MuiInputBase-root {
        color: #FFF;
        min-width: 40px;
    }

    .MuiInput-underline:before {
        border-bottom: 1px solid rgb(176, 176, 176);
    }

    .MuiInput-underline:after {
        border-bottom: 1px solid rgb(176, 176, 176);
    }

}

.remit-button {
    .Mui-disabled {
        border: 1px solid #a2a2a2 !important;
        color: #a2a2a2 !important;
    }
}
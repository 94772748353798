@import 'src/assets/scss/variable.scss';

.update-fight-record-sec{
    background-color: $color-light-black;
    padding: 18px;
    .update-fight-record-left-sec{
        border-right: $blockBorder;
        @media (max-width: 599px) {
            border-bottom: $blockBorder;
            border-right: none;
            padding-bottom: 5px;;
        }
        .fight-record-details-sec{
            padding-bottom: 18px;
        .fight-record-grid-sec{
            .updated-record-name{
                font-family:$primaryFont;
                font-size: 18px;
                line-height: 20.8px;
                color: $color-white;
                padding-bottom: 4px;
            }
            .updated-record-header{
                font-family:$primaryFont;
                font-size: 12px;
                line-height: 16px;
                color: $color-pearl;
            }
            .updated-record-text{
                font-family:$primaryFont;
                font-size: 12px;
                line-height: 16px;
                color: $color-white;
            }
        }
        .fight-record-left-grid{
            .updated-record-header{
                padding-bottom: 3px;
            }
        }
    }

    .fight-record-details-sec:last-child{
        padding-bottom: 0;
    }
    }
    .update-fight-record-right-sec{
        padding-left: 18px;
        display: flex;
        align-items: center;
        @media (max-width:599px) {
            padding-top: 5px;
            justify-content: center;
        }
        .fight-record-sec{
            .fight-record-right-data{
                display: flex;
                .endorse-btn{
                    background-color: $color-blue;
                    font-family:$primaryFont;
                    font-size: 12px;
                    line-height: 16px;
                    color: $color-white;
                    width: 21px;
                    height: 21px;
                }
                .endorse-text{
                    font-family:$primaryFont;
                    font-size: 12px;
                    line-height: 16px;
                    align-self: center;
                    color: $color-white;
                    margin: 0px 18px 0px 6px;
                }
                .endorse-addicon{
                    padding: 0;
                }
            }
        }
    }
}
@media (max-width: 599px) {
    .update-fight-record-sec{
    .update-fight-record-left-sec{
        border-right: 0;
    }
    .update-fight-record-right-sec{
        padding-top:18px;
        padding-left: 0;
        justify-content: center;
    }
}
}

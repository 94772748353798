@import 'src/assets/scss/variable.scss';

.browse-community {
  position: relative;
  // border-top: $blockBorder;
  .community-list {
    padding-top: 36px;
    .community-list-left-block{
      padding-right: 16px;
      .community-list-left {
        padding-bottom: 9px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .browse-title {
          font-size: 28.45px;
          line-height: 36px;
          font-family: $authHeaderFont;
         color: $color-white;
          text-transform: uppercase;
          // padding-top: 36px;
          // padding-bottom: 9px;
         @media (max-width: 599px) {
          font-size: 20px;
          padding-top: 18px;
          line-height: 24px;
        }
      }
    }
    @media (max-width: 599px) {
       padding-right: 0;
    }
  }
  }
  // Filter Tags
  .filter-box {
    background-color: $blockColor;
    border: $blockBorder;
    border-radius: $bRadius;
    padding: 18px;
    margin: 9px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 599px) {
      padding: 12px;
    }
    .filter-title {
      font-size: 16px;
      line-height: 20px;
      font-family: $authHeaderFont;
      color: $color-white;
    }
    .filter-btn {
      background: $color-blue;
      color: $color-white;
      font-size: 12px;
      text-transform: unset;
      padding: 5px 15px;
      margin-left: 9px;
      @media (max-width: $mobileBreakPoint) {
        margin-bottom: 15px;
      }
    }
    .filter-tags {
      display: flex;
      align-items: center;
      width: 75%;
      .filter-options {
        @media (max-width: $mobileBreakPoint) {
          padding-left: 15px;
        }
      }
      .filter-chip {
        height: 21px;
        margin-top: 9px;
        margin-right: 3px;
        color: $color-spanish-gray;
        background-color: $color-gray-dark;
        display: flex;
        align-items: center;
        @media (max-width: 599px) {
          margin-right: 9px;
        }
        .MuiChip-iconSmall {
          width: 13px;
          height: 13px;
        }
        .MuiChip-labelSmall {
          font-size: 12px;
          line-height: 16px;
          font-weight: 400;
          font-family: $primaryFont;
          position: relative;
          top: -1px;
        }
      }

      .checked-chip {
        background-color: $color-blue !important;
        color: $color-white !important;
      }
    }
  }
  .no-data-txt {
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    font-family: Roboto;
    text-align: center;
    color: #9d9d9d;
    margin-top: 16px;
    margin-bottom: 7px;
  }
  //   BEST | POPULAR | DATE | TOP |RECOMMENDED
  .bpdtr-box {
    background-color: $blockColor;
    border: $blockBorder;
    border-radius: $bRadius;
    padding: 18px;
    margin-bottom: 9px;
    display: flex;
    @media (max-width: 599px) {
      padding: 12px;
      margin-top: 9px;
    }
    .Mui-selected {
      background-color: $color-blue;
    }

    .MuiTab-wrapper {
      flex-direction: row;
    }

    .MuiTab-labelIcon {
      padding: 0;
      min-height: 42px;
      @media (max-width: 599px) {
        min-height: 34px;
      }
      .MuiTab-wrapper > svg {
        margin-bottom: 0px;
        margin-right: 10.1px;
      }
    }
    .MuiTabs-indicator {
      display: none;
    }
    .MuiTabs-root {
      min-height: 42px;
      @media (max-width: 599px) {
        min-height: 34px;
      }
    }
    .MuiTab-root {
      font-size: 18px;
      line-height: 22px;
      color: $color-white;
      font-family: $authHeaderFont;
      border-radius: $bRadius;
      padding: 0px 12px;
      @media (max-width: 599px) {
        font-size: 16px;
        line-height: 20px;
        padding: 0px 12px;
      }
    }
  }
  .infinity-scroll-view-custom {
    height: 600px;
    overflow: auto;
    display: flex;
    width: 100%;
    @media (max-width: $mobileBreakPoint) {
      height: 100%;
      overflow: visible;
    }
    .infinite-scroll-component__outerdiv {
      width: 100%;
    }
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-track {
      background-color: $color-black-very-light;
      border-radius: $bRadius;
      padding: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background: $color-blue;
      border-radius: $bRadius;
    }
  }
  // .infinity-scroll-view {
  //   .infinite-scroll-component__outerdiv {
  //     width: 100%;
  //   }
  // }
  // .infinity-scroll-view::-webkit-scrollbar {
  //   display: none;
  // }
}
.lc-popover {
  .MuiPopover-paper {
    background: $blockColor;
  }
  svg {
    height: 12px;
    width: 12px;
  }
  .MuiListItemText-root {
    padding-left: 10px;
  }
  .MuiListItemText-primary {
    font-size: 12px;
    line-height: 20px;
    font-family: $primaryFont;
    color: $color-white;
  }
  .activelink.my-option-menu {
    path,
    g {
      stroke: $color-blue;
    }
    .MuiListItemText-primary {
      color: $color-blue;
    }
  }
}
.mt-10 {
  margin-top: 10px;
}

@import 'src/assets/scss/variable.scss';

.app-menu-btn {
  text-transform: uppercase !important;
  padding: 12px 8px !important;

  span {
    display: flex;
    flex-direction: column;
  }
}

.icon-active {
  svg path {
    fill: #438ac1;
  }

  span {
    color: #438ac1;
  }
}

@import 'src/assets/scss/variable.scss';
.profile-page-container {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  display: block;
  justify-content: center;
  align-items: center;
  width: 100%;

  .profile-grid-container {
    display: flex;
    height: 100%;
    margin: 50px 0;
    width: 100%;
    color: $color-white;

    // Left Side
    .profile-left-grid {
      position: relative;
      border-right: 1px solid $color-white;
      padding-right: 24px;
      padding-left: 90.33px;

      @media (max-width: 1279px) {
        border-right: none;
        padding-left: 0px;
        padding-right: 0px;
        padding-bottom: 20px;
        &:after {
          content:' ';
          position:absolute;
          bottom: 0px;
          height: 1px;
          background-color: #fff;
          right: 0;
          left: 0;
          margin: auto -24px;
        }
      }

      .personal-details-headblock {
        position: relative;

        @media (max-width: 599px) {
          text-align: center;
        }

        .title {
          text-transform: uppercase;
          margin-bottom: 18px;
        }

        .profile-description {
          font-size: 18px;
          line-height: 24px;
          font-weight: 400;
          font-family: $primaryFont;
          @media (max-width: 599px) {
            font-size: 16px;
          }
        }
      }

      // Profile Tab bar Menu
      .profile-tab-menu {
        text-transform: uppercase;
        margin-top: 38px;
        position: relative;
        .MuiTouchRipple-root {
          display: none;
        }

        // When the web page is on the mobile size, the tab menu will display in the center
        @media (max-width: $mobileBreakPoint) {
          width: 195px;
          margin: auto;
        }
        @media (max-width: 1279px) {
          margin-top: 15px;
        }

        .MuiListItem-gutters {
          padding-left: 0px;
          padding-right: 0px;
          pointer-events: none;
          @media (max-width: 599px) {
            padding-bottom: 0px;
          }
        }

        .MuiListItemText-primary {
          font-size: 21.33px;
          line-height: 26px;
          font-family: $authHeaderFont;
          color: $color-white;
        }

        .MuiListItemIcon-root {
          min-width: 23px;
        }
      }
    }

    // Right Side
    .profile-right-grid {
      position: relative;
      padding-left: 31px;
      padding-right: 37.66px;
      @media (max-width: 1279px) {
        padding-left: 0px;
        padding-right: 0px;
      }
    }
  }
}
.profile-details-form {
  @media (max-width: 1279px) {
    margin-top: 20px;
    // .profile-picture-box {
    //   display: flex;
    // }
    .profile-details-title {
      font-size: 28px;
      line-height: 40px;
    }
    .profile-type-checks {
      .MuiListItemText-primary {
        font-size: 18px;
      }
      .MuiListItemIcon-root {
        margin-right: 12px;
      }
    }
    .profile-btn {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
}


.profile-page-1 {
  //background-image: linear-gradient(
  //    rgba(0, 0, 0, 0.5) 100%,
  //    rgba(0, 0, 0, 0.5) 100%
  //  ),
  //  url(../../../assets/images/banner/banner-2.png);
}

.profile-page-2 {
  //background-image: linear-gradient(
  //    rgba(0, 0, 0, 0.5) 100%,
  //    rgba(0, 0, 0, 0.5) 100%
  //  ),
  //  url(../../../assets/images/banner/banner-3.png);
}

.profile-page-3 {
  //background-image: linear-gradient(
  //    rgba(0, 0, 0, 0.5) 100%,
  //    rgba(0, 0, 0, 0.5) 100%
  //  ),
  //  url(../../../assets/images/banner/banner-4.png);
}

.profile-page-4 {
  //background-image: linear-gradient(
  //    rgba(0, 0, 0, 0.5) 100%,
  //    rgba(0, 0, 0, 0.5) 100%
  //  ),
  //  url(../../../assets/images/banner/banner-5.png);
}

@import 'src/assets/scss/variable.scss';

.about-add-edit-form {
  // SELECT PROFILE TYPE CHECKBOXES
  .selected-about-types {
    position: relative;

    .attach-abt-txt {
      font-size: 16px;
      line-height: 21px;
      font-weight: 400;
      font-family: $primaryFont;
      color: $color-white;
      padding: 12px 0px;
    }

    .add-this-to-other {
      padding: 0;
      font-size: 16px;
      line-height: 16px;
      margin-top: 1.5px;
      margin-left: 10px;
      min-width: 0;
      background-color: transparent;
      color: $color-blue;
      font-family: $authHeaderFont;
    }

    .MuiList-padding {
      padding: 0;
      margin-bottom: -18px;
    }
    .MuiListItemIcon-root {
      min-width: 0;
      margin-right: 9px;
    }

    .MuiListItem-gutters,
    .MuiListItem-dense {
      padding: 0;
    }

    .MuiTouchRipple-root {
      display: none;
    }

    .MuiListItem-button:hover,
    .MuiIconButton-colorSecondary:hover,
    .MuiCheckbox-colorSecondary.Mui-checked:hover {
      background-color: transparent;
    }

    .MuiListItemText-primary {
      font-size: 16px;
      line-height: 21px;
      font-weight: 400;
      font-family: $primaryFont;
      color: $color-white;
    }
  }

  // Add More Confirmation
  .add-confirmation {
    margin-top: 24px;
  }

  .add-more-about-type {
    position: relative;
    .remove-about {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      border-bottom: $blockBorder;
      margin-bottom: 18px;
    }
  }

  .add-about-field {
    width: 100%;
    padding-top: 24px;
    padding-bottom: 13.5px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-bottom: $blockBorder;

    .MuiIconButton-root {
      padding: 0;
    }
  }

  .about-dialog-container {

    @media (max-width: $mobileBreakPoint) {
      .dialog-submit-container {
        position: sticky;
        bottom: 0;
        background-color: #171717;
        padding-top: 0;
        margin-top: 20px;
      }

      .dialog-submit-button {
        margin-top: 0;
        padding:16px 32px;
      }
    }
  }
}

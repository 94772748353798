@import 'src/assets/scss/variable.scss';

.skills-main {
  span.MuiTypography-root.endorsecount {
    width: 21px;
    height: 21px;
    background: $color-blue;
    border-radius: 50%;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color-white;
  }

  .MuiButton-outlined {
    border-color: $color-blue;
    color: $color-blue;
  }
  .profile-type-about-txt {
    margin-top: 25px !important;
  }
  .text-center {
    text-align: center !important;
  }
  .skill-head {
    color: $color-white;
    font-size: 22px;
  }
  .skill-name {
    // color: gray;
    font-size: 17px;
    line-height: 30px;
  }
  .no-skill-text.no-info-txt {
    font-size: 12px !important;
    text-align: center !important;
    padding-top: 20px;
  }
  .pt-15 {
    padding-top: 15px;
  }
  .no-info-txt {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    font-size: 16px !important;
    text-align: left !important;
    .MuiIconButton-root {
      margin-right: 10px;
    }
  }
  .tabpanel-sec {
    padding: 0;
  }
}
.hide-599 {
  @media (max-width: 599px) {
    display: none !important;
  }
}
.show-599 {
  display: none !important;
  @media (max-width: 599px) {
    display: block !important;
  }
}
.restriction-box {
  font-family: Roboto;
  .rest-chip {
    background-color: $color-gray-dark !important;
    margin-top: 9px;
    margin-right: 3px;
    color: $color-white;
  }
  .rest-chip.checked-chip {
    background-color: $color-blue !important;
  }
  .mt-20 {
    margin-top: 20px;
  }
  .mb-30 {
    margin-bottom: 30px;
  }
}

.mui-accordion {
  .skills-heading {
    text-align: center;
    padding: 10px;
    border-bottom: $blockBorder;
  }
  border: $blockBorder;
  border-radius: 3px;
  background-color: $blockColor;
  color: $color-white;
  border-bottom: 0;
  .profile-type-about-txt {
    // border-bottom: 2px solid $color-white;
    width: 100%;
  }
  // .MuiCollapse-entered {
  //   margin-bottom: 20px;
  // }
  .MuiAccordion-rounded {
    background-color: transparent;
    color: $color-white;
    border-bottom: $blockBorder;
    svg {
      color: $color-white;
      position: relative;
    }
    // .MuiAccordionDetails-root {
    //   svg {
    //     top: 12px;
    //   }
    // }
  }
  .MuiAccordionSummary-root {
    background: $color-gray-dark;
  }
  .MuiAccordionSummary-content {
    font-family: roboto;
  }
  .MuiAccordionSummary-expandIcon.Mui-expanded {
    padding: 0;
  }
  .Mui-expanded {
    margin: 0 !important;
    font-family: roboto;
  }
  .MuiCollapse-wrapperInner {
    .MuiAccordionDetails-root {
      border-bottom: 1px solid #2e353a;
      align-items: center;
      justify-content: space-between;
      padding: 5px 16px;
    }
  }
}

.endorsement-main {
  .mb-15 {
    margin-bottom: 15px;
  }
  .skills-heading {
    color: $color-white;
    font-size: 20px;
    margin-bottom: 10px;
  }
}
.mw-950 {
  .MuiDialog-paper {
    max-width: 950px;
  }
}

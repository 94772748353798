@import 'src/assets/scss/variable.scss';

.restriction-box {
  padding: 18px;
  background: $blockColor;
  border: $blockBorder;
  border-radius: $bRadius;
  @media (max-width: 599px) {
    padding: 12px;
  }
  .restriction-box-title {
    text-transform: uppercase;
    font-size: 16px;
    line-height: 20px;
    font-family: $authHeaderFont;
    color: $color-white;
    margin-bottom: 9px;
  }

  .filter-tags {
    display: flex;

    .filter-chip {
      height: 21px;
      margin-right: 3px;
      margin-bottom: 3px;
      color: $color-spanish-gray;
      background-color: $color-gray-dark;
      display: flex;
      align-items: center;
      @media (max-width: 599px) {
        margin-right: 9px;
      }
      .MuiChip-iconSmall {
        width: 13px;
        height: 13px;
      }
      .MuiChip-labelSmall {
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        font-family: $primaryFont;
        position: relative;
        top: -1px;
        padding-left: 9px;
        padding-right: 9px;
      }
    }

    .checked-chip {
      background-color: $color-blue !important;
      color: $color-white !important;
    }
  }

  .edit-rest-btn {
    padding: 0;
    height: 30px;
    border-radius: $bRadius;
    color: $color-white;
    background-color: $color-blue;
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    font-family: $primaryFont;
    text-transform: capitalize;
    margin-top: 14px;
    &:hover {
      color: $color-white;
      background-color: $color-blue;
    }
  }
  .edit-rest-btn.Mui-disabled{
    background-color: $color-blue;
    color: $color-white;
  }
  .pb-20 {
    padding-bottom: 20px;
  }
}

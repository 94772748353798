@import 'src/assets/scss/variable.scss';

.search-event-cover {
  .addevntbtn {
    padding: 6.4px 0px 7.6px 0px;
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    font-family: $primaryFont;
    background-color: $color-blue;
    color: $color-light-blue;
    &:hover {
      background-color: $color-blue;
      color: $color-light-blue;
    }
  }

  //   SEARCHED EVENT DATA TABLE
  .se-table {
    margin-top: 33px;
    .noetxt {
      font-size: 16px;
      line-height: 18px;
      font-weight: 400;
      font-family: $primaryFont;
      color: #3d3e3f;
      text-align: center;
    }

    ::-webkit-scrollbar {
      height: 6px;
    }

    ::-webkit-scrollbar-track {
      background-color: $color-black-very-light;
      border-radius: $bRadius;
    }

    ::-webkit-scrollbar-thumb {
      background: $color-blue;
      border-radius: $bRadius;
    }
    .MuiTable-root {
      min-width: 700px;
      width: 100%;
    }
    .MuiTableHead-root {
      .MuiTableRow-head {
        .MuiTableCell-root {
          font-size: 16px;
          line-height: 21px;
          font-weight: 700;
          font-family: $primaryFont;
          color: $color-white;
          border-bottom: none;
        }
        .MuiTableCell-sizeSmall {
          padding: 0px;
          padding-right: 5px;
          padding-bottom: 18px;
        }
        .echeck {
          width: 30px;
        }
        .ename {
          width: 161px;
        }
        .estart {
          width: 68px;
        }
        .eend {
          width: 74px;
        }
        .elocation {
          width: 114px;
        }
        .edescription {
          width: 191px;
        }
      }
    }

    .MuiTableBody-root {
      padding-bottom: 17px;
      .MuiTableCell-root {
        font-size: 16px;
        line-height: 21px;
        font-weight: 400;
        font-family: $primaryFont;
        color: $color-white;
        border-bottom: none;
      }

      &:last-child {
        margin-bottom: 0px;
      }
      .MuiTableCell-sizeSmall {
        padding: 0px;
        padding-right: 5px;
        padding-bottom: 17px;
      }

      .echeck {
        width: 30px;
        .MuiCheckbox-root {
          padding: 0;
          margin: 0;
        }
      }
      .ename {
        width: 161px;
      }
      .estart {
        width: 68px;
      }
      .eend {
        width: 74px;
      }
      .elocation {
        width: 114px;
      }
      .edescription {
        width: 191px;
      }
    }
  }
}

@import 'src/assets/scss/variable.scss';

.app-bar {
  :global(.MuiToolbar-regular) {
    min-height: 54px !important;
    @media (min-width: 600px) {
      min-height: 54px !important;
    }
  }
}

.app-menu-btn {
  text-transform: uppercase !important;
  position: relative !important;
  margin-left: 50px !important;

  @media (min-width: $mobileBreakPoint) and (max-width: 1100px) {
    margin-right: 0 !important;
    margin-left: 20px !important;
  }

  @media (min-width: 1600px) {
    margin-left: 100px !important;
  }
  @media (min-width: 1860px) {
    margin-left: 129px !important;
  }
  &:hover {
    color: $color-blue !important;
  }
}

.app-bar-cm-user {
  position: relative !important;
  margin-left: 15px !important;
  // @media (max-width: 1279px) {
  //   display: none;
  // }

  .cm-user-title {
    text-transform: uppercase !important;
    font-size: 21px !important;
    line-height: 23px !important;
    font-family: $authHeaderFont !important;
    color: $color-white !important;
    display: flex !important;
    align-items: center !important;
    span {
      color: $color-blue !important;
    }
  }
}

.app-bar-searchbtn {
  position: relative !important;
  padding: 5px !important;
  margin-right: 17px !important;
  margin-left: 50px !important;
  @media (min-width: 1860px) {
    margin-left: 200px !important;
  }
}

.app-bar-notification-btn {
  position: relative !important;
  padding: 5px !important;
  margin-right: 12px !important;
  //HIDE-MVP: CM-78
  //added to hide search
  margin-left: 96px !important;
  @media (min-width: 1860px) {
    margin-left: 246px !important;
  }
  //
}

.app-bar-notification-btn.message-icon {
  //HIDE-MVP: CM-78
  //added to hide search
  margin-left: 0 !important;
  //
  .msg-icon {
    width: 25px !important;
    height: 25px !important;
  }
}

.app-bar, .admin-app-bar {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  right: initial !important;
  bottom: initial !important;
  padding-right: 0 !important;
}

.logo-anchor {
  display: flex;
  align-items: center;
}

.auth-login-button {
  background: $color-white !important;
  color: #101010 !important;
  border-radius: $bRadius !important;
  font-size: 21px !important;
  font-family: $authHeaderFont !important;
  padding: 1.64px 17.9px !important;
}

@import 'src/assets/scss/variable.scss';

.left-profile-details-box {
  position: relative;
  margin-top: 9px;
  width: 100%;
  padding: 18px;
  background-color: $blockColor;
  border: $blockBorder;
  border-radius: $bRadius;
  @media (max-width: 599px) {
    padding: 12px !important; // addmin has common class
  }
  .MuiIconButton-root {
    padding: 0;
    @media (max-width: 599px) {
      font-size: 1rem !important; // addmin has common class
    }
  }
  .profile-left-title {
    text-transform: uppercase;
    font-size: 21.33px;
    line-height: 26px;
    font-family: $authHeaderFont;
    color: $color-white;
    @media (max-width: 599px) {
      font-size: 16px !important; // addmin has common class
      line-height: 20px !important; // addmin has common class
    }
  }
  .profile-completion-text{
    font-size: 16px;
    line-height: 25px;
    padding-left: 18px;
    font-family: $authHeaderFont;
    color: $color-blue;
    text-decoration: underline;
    cursor: pointer;
  }
  .profile-details-data {
    position: relative;
    margin-top: 9px;
    font-size: 16px;
    line-height: 21px;
    font-weight: 400;
    font-family: $primaryFont;
    .profile-details-field {
      color: #b4babf;
      min-width: 142px;
      @media (max-width: 599px) {
        font-size: 14px !important; // addmin has common class
        line-height: 20px !important; // addmin has common class
      }
    }

    .profile-details-field-val {
      color: $color-white;
      cursor: pointer;
      @media (max-width: 599px) {
        font-size: 14px !important; // addmin has common class
        line-height: 20px !important; // addmin has common class
      }
    }
  }
}

.profile-type-slider {
  position: relative;
  margin-top: 9px;

  .MuiIconButton-root {
    padding: 0;
    @media (max-width: 599px) {
      font-size: 1rem !important; // addmin has common class
    }
  }

  .profile-type-about-txt {
    font-size: 16px;
    line-height: 21px;
    font-weight: 400;
    font-family: $primaryFont;
    color: $color-white;
    text-align: left;
    margin-top: 9px;
    word-break: break-word;
    @media (max-width: 599px) {
      font-size: 14px !important; // addmin has common class
    }
  }

  .no-info-txt {
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    font-family: $primaryFont;
    color: $color-spanish-gray;
    margin-top: 16px;
    margin-bottom: 7px;
    text-align: center;
  }

  .skel-prof-type-tab {
    width: 50px;
    height: 30px;
    border-radius: 3px;
    margin-right: 9px;
  }

  .title-block {
    display: flex;
    justify-content: space-between;

    .tab-block-title {
      text-transform: uppercase;
      width: 100%;
      display: flex;
      justify-content: space-between;
      font-size: 21.33px;
      line-height: 26px;
      font-family: $authHeaderFont;
      color: $color-white;
      @media (max-width: 599px) {
        font-size: 14px !important; // addmin has common class
        line-height: 20px !important; // addmin has common class
      }
    }
  }
  .edit-about-btn {
    margin-top: 9px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .edit-about-btn {
    margin-top: 9px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .slider-container {
    position: relative;
    width: 100%;
    margin-top: 9px;

    .MuiTabs-root {
      min-height: 0;
    }
    .MuiTab-root {
      min-height: 0;
      padding: 5px 9px;
      border-radius: $bRadius;
      @media (min-width: 600px) {
        min-width: 0;
      }
    }

    .MuiTab-wrapper {
      font-size: 16px;
      line-height: 20px;
      font-family: $authHeaderFont;
      @media (min-width: 600px) {
        min-width: 0;
      }
      @media (max-width: 599px) {
        font-size: 14px !important; // addmin has common class
      }
    }

    .Mui-selected {
      background-color: $color-blue;
    }

    .MuiTabs-indicator {
      display: none;
    }
  }

  // About Slider
  .about-tab {
    position: relative;
    width: 100%;
    padding: 18px;
    background-color: $blockColor;
    border: $blockBorder;
    border-radius: $bRadius;
    margin-top: 9px;
    @media (max-width: 599px) {
      padding: 12px !important; // addmin has common class
    }
  }

  //   Discipline
  .discipline-tab {
    position: relative;
    width: 100%;
    padding: 18px;
    background-color: $blockColor;
    border: $blockBorder;
    border-radius: $bRadius;
    margin-top: 9px;
    @media (max-width: 599px) {
      padding: 12px !important; // addmin has common class
    }
  }
}

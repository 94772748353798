@import 'src/assets/scss/variable.scss';

.fightstyle-main {
  color: $color-white;
  font-family: $primaryFont;
  .fightstyle-inner {
    .MuiListItem-root {
      border-bottom: 1px solid $color-lava;
      padding: 10px;
    }
    .remove-button {
      color: $color-white;
      background: $color-blue;
      font-size: 12px;
      text-transform: unset;
      font-weight: 300;
      padding: 5px 12px;
    }
    .fighter-image {
      width: 60px;
      height: 60px;
      margin-right: 10px;
    }
    .fighter-intro {
      display: flex;
      align-items: center;
      width: 100%;
      @media (max-width: $mobileBreakPoint) {
        display: block;
      }
    }
    .fighter-name {
      cursor: pointer;
      font-size: 18px;
      display: block;
      font-weight: 500;
    }
    .fighter-skills {
      font-size: 16px;
      color: $color-pearl;
      font-weight: 100;
    }
  }
  .no-data-txt {
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    font-family: Roboto;
    text-align: center;
    color: $color-spanish-gray;
    margin-bottom: 7px;
  }
}

@import 'src/assets/scss/variable.scss';

.community-stickied-post {
  background-color: #202020;
  border: $blockBorder;
  border-radius: $bRadius;
  min-width: 291px;
  margin-left: 4.5px;
  margin-right: 4.5px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;

  &:first-child {
    margin-left: 18px;
  }

  &:last-child {
    margin-right: 18px;
  }

  // POST HEADER
  .post-header {
    padding: 15px 18px 9px 18px;

    .post-name-statics {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      // margin-bottom: 3px;
      .post-left-sec {
        width: 65%;

        .post-name {
          display: block;
          font-size: 16px;
          line-height: 21px;
          font-weight: 600;
          font-family: $primaryFont;
          color: $color-white;
          cursor: pointer;
          padding-bottom: 3px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 100%;
        }
      }

      .post-statics {
        display: flex;
        // padding-top: 7px;
        .MuiIconButton-root {
          padding: 0;
        }

        .statics-val {
          font-size: 12px;
          line-height: 18px;
          font-weight: 400;
          font-family: $primaryFont;
          color: #b4babf;
        }

        .fill-blue {
          path {
            fill: $color-blue;
          }
        }

        .fill-red {
          path {
            fill: #c01515;
          }
        }

        .up-val {
          margin-right: 13px;
        }
      }
    }

    .post-date-time {
      font-size: 12px;
      line-height: 18px;
      font-weight: 400;
      font-family: $primaryFont;
      color: #6a6a6a;
      padding-bottom: 3px;
    }

    .posted-by {
      font-size: 12px;
      line-height: 18px;
      font-weight: 600;
      font-family: $primaryFont;
      color: $color-white;
      cursor: pointer;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 90%;
      display: block;
    }
  }

  .post-content::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }

  //   POST CONTENT
  .post-content {
    padding: 0px 18px 18px 18px;
    border-bottom: $blockBorder;
    word-break: break-word;
    flex: 1;
    // min-height: 144px;
    // height: 38%;
    // overflow: scroll;
    .event-text {
      font-size: 16px;
      line-height: 20.8px;
      font-weight: 400;
      font-family: $primaryFont;
      color: $color-white;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      word-break: break-word;
      min-height: 45px;
    }

    a {
      color: $color-blue;
    }

    .event-image {
      width: 100%;
      height: 83px;
    }
  }

  // POST ACTIONS
  .post-actions {
    padding: 8px 18px 18px 18px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .action-row {
      display: flex;
      align-items: center;

      .action-box {
        width: 50%;

        .act-btn {
          padding: 0;
          min-width: 0;
          font-size: 12px;
          line-height: 18px;
          font-weight: 400;
          font-family: $primaryFont;
          background-color: transparent;
          color: #b4babf;
          text-transform: inherit;

          .MuiButton-startIcon {
            margin-right: 6.8px;
          }
        }
      }
    }

    .act-rw-1 {
      margin-bottom: 4px;
    }

    .admin-button {
      margin-top: 7px;
      padding: 0;
      height: 30px;
      background-color: $color-blue;
      color: #d9e8f3;
      font-size: 12px;
      line-height: 18px;
      font-weight: 400;
      font-family: $primaryFont;
      text-transform: capitalize;

      .MuiButton-endIcon {
        margin-left: 6.1px;
      }
    }
  }
}

.admin-tools {
  padding: 0 15px;
  width: 225px;

  .MuiListItem-gutters,
  .MuiList-padding {
    padding: 0;
  }

  .MuiListItem-gutters {
    cursor: pointer;
  }
}

* {
    margin: 0;
    padding: 0;
}

.col-1 {
    width: 8.33%;
}

.col-2 {
    width: 16.66%;
}

.col-3 {
    width: 25%;
}

.col-4 {
    width: 33.33%;
}

.col-5 {
    width: 41.66%;
}

.col-6 {
    width: 50%;
}

.col-7 {
    width: 58.33%;
}

.col-8 {
    width: 66.66%;
}

.col-9 {
    width: 75%;
}

.col-10 {
    width: 83.33%;
}

.col-11 {
    width: 91.66%;
}

.col-12 {
    width: 100%;
}


.container {
    max-width: 1000px;
    padding: 0 20px;
    margin: 0 auto;
}

.flex {
    display: flex;
    width: 100%;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}


.payment-section {
    background-color: #000;
    height: 100vh;
    position: relative;
}

.payment-section .container { position: absolute; width: 100%; max-width: 1000px; top: 50%; left: 50%; transform: translate(-50%, -50%); }

// .Product-details-wrapper { display: block; width: auto; }

.product-detail {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 0 25px;
    color: #fff;
}

.product-detail p.product-title {
    font-size: 20px;
    font-weight: 600;
}

.product-detail p.product-desc {
    font-size: 16px;
    max-width: 80%;
}

.product-detail h4.product-price {
    font-size: 50px;
}

.product-img { display: block; position: relative; width: 100%; }


.product-detail .product-img img {
    width: 100%;
    height: 400px;
    object-fit: cover;
    border-radius: 5px;
    margin-top: 10px;
}




.product-checkout {
    display: flex;
    flex-direction: column;
    margin: 0 25px;
    background: #F4F4F4;
    border-radius: 10px;
    padding: 35px 30px;
    height: 100%;
}

div.checkout {
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 100%;
}

.card-pay {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    width: fit-content;
    background: #FBFBFB;
    padding: 10px 80px 10px 10px;
    border: 1px solid #000;
    border-radius: 10px;
    box-shadow: 0 0 5px 2px #e7e7e7;
}

.card-info-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.card-info span {
    margin-bottom: 0px;
    display: block;
}

.card-info input {
    max-width: 100%;
    width: -webkit-fill-available;
    padding: 10px;
    font-size: 16px;
    border-radius: 5px;
    border: none;
    box-shadow: 0 0 3px 1px #ccc;
}

.card-num-wrapper {
    position: relative;
}

input#cardnum {
    padding-right: 150px;
}

img.card-img {
    position: absolute;
    top: 50%;
    right: 10px;
    height: 20px;
    transform: translate(0, -50%);
}

.card-info-wrapper .col-12.flex {
    gap: 10px;
}

.card-info-wrapper .col-12.flex .col-6 {
    width: calc(50% - 5px);
    gap: 10px;
}

.button-text {
    margin-bottom: 0px;
}

.col-12.checkout-btn-wrapper {
    margin-top: 20px;
}

button.checkout-btn {
    position: relative;
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: none;
    background: #000;
    border-radius: 6px;
    color: #fff;
    cursor: pointer;
}

button.checkout-btn::after {
    content: '';
    display: block;
    width: 1.2em;
    height: 1.2em;
    position: absolute;
    left: calc(50% - 0.75em);
    top: calc(50% - 0.75em);
    border: 0.15em solid transparent;
    border-right-color: white;
    border-radius: 50%;
    animation: button-anim 0.7s linear infinite;
    opacity: 0;
}

@keyframes button-anim {
    from {
        transform: rotate(0);
    }

    to {
        transform: rotate(360deg);
    }
}

.checkout-btn.loading {
    color: transparent;
}

.checkout-btn.loading::after {
    opacity: 1;
}







.powerd-by.flex {
    justify-content: center;
    gap: 30px;
    height: 30px;
    margin-top: 10px;
}


@media screen and (max-width: 1024px) {

    .payment-section {  
        height: 100vh;
        padding-top: 170px;
        padding-bottom: 100px;
    }

    .payment-section .container { top: 55%; }


    .card-info input {
        font-size: 14px;
    }

    .card-info-wrapper .col-12.flex {
        flex-direction: column;
    }

    .card-info-wrapper .col-12.flex .col-6 {
        width: 100%;
    }

    img.card-img {
        height: 15px;
    }

    input#cardnum {
        padding-right: 106px;
    }

    .product-checkout {
        margin: 0 15px;
    }

    .product-detail {
        margin: 0 15px;
    }

    
}

@media screen and (max-width: 769px) {


    .col-1 {
        width: 100%;
    }

    .col-2 {
        width: 100%;
    }

    .col-3 {
        width: 100%;
    }

    .col-4 {
        width: 100%;
    }

    .col-5 {
        width: 100%;
    }

    .col-6 {
        width: 100%;
    }

    .col-7 {
        width: 100%;
    }

    .col-8 {
        width: 100%;
    }

    .col-9 {
        width: 100%;
    }

    .col-10 {
        width: 100%;
    }

    .col-11 {
        width: 100%;
    }

    .col-12 {
        width: 100%;
    }

    .payment-section { height: 100%; }
    .payment-section .container { position: relative; top: 0; left: 0; width: 100%; transform: none; }

    .flex.payment-wrapper {
        flex-direction: column;
        gap: 50px;
    }

    .product-checkout {
        margin: 0;
    }

    .product-detail {
        margin: 0;
    }

}

@media screen and (max-width: 540px) {

    .product-checkout {
        padding: 35px 15px;
    }

    .powerd-by.flex {
        color: #646464;
        width: 100%;
    }

    img.card-img {
        height: 13px;
    }

    input#cardnum {
        padding-right: 95px;
    }

}
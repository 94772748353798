@import 'src/assets/scss/variable.scss';

.community-list-block {
    margin-top: 9px;
    background-color: $blockColor;
    border-radius: $bRadius;
    border: $blockBorder;
    .community-list-sec{
      padding: 18px;
      .all-tab-header{
        color: $color-white;
        font-size: 21.33px;
        line-height: 28px;
        font-family: $authHeaderFont;
    }
    .com-list-head-box {
      display: flex;
      justify-content: space-between;
      margin: 9px 0px 18px 0;
      align-items: center;
      padding: 0;
      @media (max-width: 599px) {
        // display: block;
        padding: 12px 12px 0 12px;
      }
      .left-about {
        display: flex;
        width: 80%;
        .MuiAvatar-root {
          width: 60px;
          height: 60px;
          margin-right: 18px;
          @media (max-width: 599px) {
            width: 40px;
            height: 40px;
            margin-right: 14px;
          }
        }
        .com-name-created {
            align-self: center;
          .com-name {
            font-size: 28.45px;
            line-height: 36px;
            font-family: $authHeaderFont;
            color: $color-white;
            display: flex;
            align-items: center;
            @media (max-width: 599px) {
              font-size: 18px;
              line-height: 24px;
            }
            cursor: pointer;
            svg {
              margin-left: 9.5px;
              width: 20%;
            }
          }
          .com-created {
            font-size: 16px;
            line-height: 20px;
            font-family: $authHeaderFont;
            color: $color-white;
            @media (max-width: 599px) {
              font-size: 14px;
              line-height: 20px;
            }
            span {
              color: $color-blue;
            }
          }
        }
      }
    }
    .connection-have-btn{
      padding: 6px 16px;
      color: $color-light-blue;
      background-color: $color-blue;
      font-size: 12px;
      line-height: 18px;
      font-family: $primaryFont;
      text-transform: capitalize;
      border-radius: $bRadius;

  }
  .connection-have-btn:hover{
    color: $color-light-blue;
      background-color: $color-blue;
  }

    .join-btn{
        width: 20%;
        text-align: end;

    }
  }
  .profile-following-btn{
    padding: 6px 16px;
    color: $color-white;
    background-color: $color-gray-dark;
    font-size: 12px;
    line-height: 18px;
    font-family: $primaryFont;
    text-transform: capitalize;
    border-radius: $bRadius;

}
}

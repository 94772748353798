@import 'src/assets/scss/variable.scss';

.community-view {
  .cover-photo-container {
    .MuiButtonBase-root {
      width: 100%;
      padding: 0;
    }

    .preview-cover {
      position: relative;
      width: 100%;
      height: 308px;
      border-radius: $bRadius;
      cursor: pointer;
      @media (max-width: 959px) {
        height: 200px;
      }
    }
    .camera-icon {
      position: absolute;
      top: 12px;
      right: 12px;
    }

    .upload-button {
      width: 100%;
      border-radius: $bRadius;
      padding: 146px 0px;
      font-size: 12px;
      line-height: 16px;
      font-weight: 400;
      font-family: $primaryFont;
      text-transform: capitalize;
      background-color: #1b1b1b;
      border: $blockBorder;
      color: #9d9d9d;
      @media (max-width: 959px) {
        padding: 0px;
        height: 200px;
      }
    }

    .MuiButton-endIcon {
      position: absolute;
      top: 12px;
      right: 12px;
    }
  }

  //   Community Name | Logo | Member
  .community-img-member {
    margin-top: -22px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 959px) {
      display: block;
      margin-top: 9px;
      margin-bottom: 9px;
    }
    .MuiBox-root-17 {
      @media (max-width: 599px) {
        align-items: flex-start;
      }
    }
    .comm-image {
      z-index: 1;
      .community-avatar-img,
      .MuiIconButton-root {
        padding: 0;
        width: 115px;
        height: 115px;
        margin-left: 36px;
        margin-right: 12px;

        @media (max-width: 959px) {
          width: 80px !important;
          height: 80px !important;
          margin-left: 0;
          margin-right: 0px;
        }
        @media (max-width: 959px) {
          margin-left: 0px;
          margin-right: 0px;
        }
      }

      .MuiAvatar-root {
        width: 115px;
        height: 115px;
        @media (max-width: 959px) {
          width: 80px !important;
          height: 80px !important;
        }
      }
      .camera-icon {
        position: absolute;
        right: -3px;
        bottom: -3px;
        @media (max-width: 959px) {
          right: -8px;
          bottom: -8px;
        }
      }
    }

    .community-name {
      // display: flex;
      // align-items: center;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      // width: 90%;
      display: block;

      // margin-left: 27px;
      font-size: 28.45px;
      line-height: 36px;
      font-family: $authHeaderFont;
      @media (max-width: 599px) {
        font-size: 20px;
        margin-left: 14px;
      }
      svg {
        margin-left: 18.7px;
        @media (max-width: 599px) {
          margin-left: 9px;
        }
      }
    }
  }
  .show-599-block{
    @media (max-width: 399px) {
     display: block !important;
     text-align: center;
    }
  }
  .community-member {
    text-transform: uppercase;
    font-size: 21.33px;
    line-height: 26px;
    font-family: $authHeaderFont;
    color: $color-white;
    @media (max-width: 959px) {
      font-size: 18px;
      line-height: 22px;
    }
    @media (max-width: 399px) {
      margin-top: 9px;
    }
    span {
      color: $color-blue;
    }
  }

  .cmt-join-btn {
    padding: 4px 21px;
    margin-left: 18px;
    font-size: 18px;
    line-height: 22px;
    font-family: $authHeaderFont;
    color: $color-black-very-light;
    background: $color-white;
    min-width: 0;

    @media (max-width: 959px) {
      margin-left: 0;
      padding: 3px 18px;
      font-size: 16px;
      line-height: 20px;
    }
  }
  .cmt-join-btn.Mui-disabled {
    background: $color-white;
  }
  //   Discussions | About | Members | Events | Related
  .damer-container {
    position: relative;
    background-color: $blockColor;
    border: $blockBorder;
    border-radius: $bRadius;
    padding: 18px;
    margin-top: -32px;
    margin-bottom: 9px;
    display: flex;
    justify-content: space-between;
    @media (max-width: 959px) {
      display: block;
      margin-top: 18px;
      padding: 18px;
    }

    .damer-buttons {
      margin-left: 160px;
      @media (max-width: 959px) {
        margin-left: 0;
      }

      .MuiTabs-root,
      .MuiTab-root {
        min-height: 0;
        @media (min-width: 600px) {
          min-width: 0;
        }
      }

      .MuiTab-root {
        padding: 4px 9px;
        border-radius: $bRadius;
        background-color: $color-white;
        color: $color-black-very-light;
        margin-right: 9px;
      }

      .Mui-selected {
        background-color: $color-blue;
        .MuiTab-wrapper {
          color: $color-white;
        }
      }

      .MuiTab-wrapper {
        font-size: 18px;
        line-height: 22px;
        font-family: $authHeaderFont;
        color: $color-black-very-light;
      }

      .MuiTab-textColorInherit {
        opacity: 1;
      }

      .MuiTabs-indicator {
        display: none;
      }
    }
  }

  .com-block-actions {
    @media (max-width: 959px) {
      margin-top: 9px;
      text-align: right;
    }
    @media (max-width: 399px) {
      text-align: center;
     }
    .MuiIconButton-root {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      padding: 0;
      margin-left: 5px;
      background-color: $color-blue;
      svg {
        width: 16px;
        height: 16px;
        fill: $color-white;
      }
    }
  }

  // Community Grid Container
  .community-grid-container {
    position: relative;
    display: flex;
    justify-content: space-between;
    @media (max-width: $mobileBreakPoint) {
      display: block;
    }

    // Right Side
    .community-right {
      width: 38%;
      height: 100%;
      padding-left: 16px;

      @media (max-width: $mobileBreakPoint) {
        width: 100%;
        padding-left: 0;
      }
    }

    // Left Side
    .community-left {
      width: 62%;
      height: 100%;

      @media (max-width: $mobileBreakPoint) {
        margin-top: 18px;
        width: 100%;
      }
    }
  }
  .community-not-available-text {
    margin-top: 3px;
    font-size: 37.92px;
    line-height: 47px;
    color: $color-white;
    font-family: $authHeaderFont;
    text-align: center;
    margin-bottom: 19.5px;
  }
}

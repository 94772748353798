@import 'src/assets/scss/variable.scss';

// CREATE POST CLICK BOX
.create-post-show-box {
  padding: 16px;
  background-color: $blockColor;
  border: $blockBorder;
  border-radius: $bRadius;
  display: flex;
  align-items: center;
  margin-bottom: 9px;

  .MuiAvatar-circular {
    width: 45px;
    height: 45px;
    margin-right: 9px;
  }

  .create-post-text {
    width: 100%;
    height: 100%;
    border-radius: $bRadius;
    background-color: #202020;
    padding: 8.5px 15px;
    font-size: 18px;
    line-height: 24px;
    font-family: $primaryFont;
    color: #b4babf;
    border: 1px solid #2e353a;
    cursor: pointer;
  }
}

// CREATE NEW POST
.creating-post-box {
  padding: 18px;
  background-color: $blockColor;
  border: $blockBorder;
  border-radius: $bRadius;
  margin-bottom: 9px;
  .d-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  // USER DETAILS
  .user-details {
    display: flex;
    align-items: center;
    .MuiAvatar-root {
      width: 45px;
      height: 45px;
    }

    .user-name {
      margin-left: 9px;
      font-size: 16px;
      line-height: 21px;
      font-weight: 400;
      font-family: $primaryFont;
      color: 0;
    }
  }

  // CREATE POST DETAILS BOX
  .post-title-input {
    margin-top: 18px;

    .field-character-count {
      float: right;
      margin-right: -14px;
      font-size: 12px;
      line-height: 18px;
      font-weight: 400;
      font-family: $primaryFont;
      color: $color-white;
    }

    .cp-restriction-box {
      .restriction-txt {
        font-size: 16px;
        line-height: 21px;
        font-weight: 400;
        font-family: $primaryFont;
        color: $color-white;
      }

      .restriction-chips {
        display: flex;
        overflow-x: scroll;
        overflow-y: hidden;
        &::-webkit-scrollbar {
          display: none;
        }
        .rest-chip {
          height: 21px;
          margin-top: 9px;
          margin-right: 3px;
          color: #9d9d9d;
          background-color: #3d3e3f;
          display: flex;
          align-items: center;
          @media (max-width: 599px) {
            margin-right: 9px;
          }
          .MuiChip-iconSmall {
            width: 13px;
            height: 13px;
          }
          .MuiChip-labelSmall {
            font-size: 12px;
            line-height: 16px;
            font-weight: 400;
            font-family: $primaryFont;
            position: relative;
            top: -1px;
          }
        }

        .checked-chip {
          background-color: $color-blue !important;
          color: $color-white !important;
        }
      }
    }
  }
  .cp-cnt {
    margin-top: 8.5px;
  }

  .select-posting-type {
    .MuiListItemIcon-root {
      min-width: 0;
      margin-right: 0px;
    }

    .type-text-block {
      display: flex;
      justify-content: space-between;
      max-width: inherit;

      @media (max-width: 599px) {
        display: block;
      }

      .label-name {
        font-size: 16px;
        line-height: 21px;
        font-weight: 400;
        font-family: $primaryFont;
        color: $color-white;
      }

      .label-name {
        @media (max-width: 599px) {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
    .MuiTouchRipple-root {
      display: none;
    }

    .MuiList-padding,
    .MuiListItem-dense {
      padding-top: 0;
      padding-bottom: 0;
    }
    .MuiListItem-gutters {
      padding-left: 4px;
    }

    .MuiIconButton-colorSecondary:hover,
    .MuiCheckbox-colorSecondary.Mui-checked:hover {
      background-color: transparent;
    }
  }

  // CREATE POST BUTTON
  .create-post-btn {
    padding: 0;
    height: 54px;
    font-size: 21.33px;
    line-height: 26px;
    font-family: $authHeaderFont;
    background-color: $color-blue;
    color: $color-white;
    &:hover {
      background-color: $color-blue;
      color: $color-white;
    }
    &:disabled {
      background-color: $color-fb;
    }
  }
}

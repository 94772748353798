@import 'src/assets/scss/variable.scss';

.text-editor-container {
  padding: 18px;
  background-color: #171717;
  border: 1px solid #2e353a;
  border-radius: 3px;
  margin-bottom: 9px;
}

.text-field-grid {
  .editior-error-message {
    font-family: $primaryFont;
    color: $color-pin;
    margin-left: 12px;
  }
}

.text-editor {
  border: 1px solid #2e353a;
  min-height: 180px !important;
  font-family: Roboto, sans-serif;
  padding: 0 10px;


  a {
    background: transparent;
    color: #438ac1;
  }

  .text-editor_text {
    margin: 12px 0px;
  }
}

.mentions-list {
  top: -9999px;
  left: -9999px;
  position: absolute;
  z-index: 1;
  background: white;
  box-shadow: 0 1px 5px rgba(0, 0, 0, .2);
  max-height: 150px;
  overflow: auto;

  .mentions-list_item {
    border-bottom: 1px solid #f1f1f1;
    padding: 7px 5px;
    color: black;
  }
}

.text-editor-toolbar {
  padding: 6px 5px 0;
  border-radius: 2px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: white;
  flex-wrap: wrap;
  font-size: 15px;
  margin-bottom: 5px;
  -webkit-user-select: none;
  user-select: none;
  background: none;
  border: 1px solid #2e353a;

  .text-editor-toolbar_item-container {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    position: relative;
    flex-wrap: wrap;

    .text-editor_mention-item {
      padding: 7px 5px;
      color: white;
      border-bottom: 1px solid white;
      border-radius: 3px;
      margin-bottom: 4px;
    }

    .text-editor-toolbar_item {
      width: 36px;
      height: 36px;
      padding: 5px;
      min-width: 25px;
      border-radius: 2px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      background: white;
      text-transform: capitalize;
      background: none;
      border: none;
    }
  }
}

.text-editor-popup-mentions {
  position: absolute;
  z-index: 1;
  background: white;
  box-shadow: rgb(0 0 0 / 20%) 0px 1px 5px;
  max-height: 150px;
  overflow: auto;
}

.text-editor-popup {
  position: absolute;
  top: 35px;
  left: 5px;
  display: flex;
  flex-direction: column;
  width: 235px;
  padding: 15px;
  border-radius: 2px;
  z-index: 100;
  background: #000000;
  font-family: Roboto, sans-serif;
  border: 1px solid #2e353a;
  box-shadow: none;
  height: auto;

  label {
    font-size: 15px;
  }

  span {
    margin-left: 5px;
  }

  .text-editor-popup_input {
    margin-top: 5px;
    border-radius: 2px;
    border: 1px solid #F1F1F1;
    padding: 0 5px;
    margin-bottom: 5px;
    height: 28px;
  }

  .text-editor-popup_input:focus {
    outline: none;
  }

  .text-editor-popup_buttons-container {
    margin: 0 auto;

    .text-editor-popup_button {
      margin-left: 10px;
      width: 75px;
      height: 30px;
      border: 1px solid #F1F1F1;
      border-radius: 2px;
      cursor: pointer;
      background: white;
      text-transform: capitalize;
    }

    .text-editor-popup_button:hover {
      box-shadow: 1px 1px 0px #bfbdbd;
    }

    .text-editor-popup_button:disabled {
      background: #ece9e9;
    }
  }


  .text-editor-popup_option {
    margin-bottom: 20px;
  }
}

.amount-selector {
  width: 70px;
  .MuiTypography-colorTextSecondary{
    color: #FFF;
  }
  .MuiFilledInput-adornedStart {
    padding-left: 5px;
    padding-bottom: 10px;
  }
  .MuiFilledInput-root {
    height: 35px;
  }
  color: $color-white;
}
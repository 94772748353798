@import 'src/assets/scss/variable.scss';

.claim-dialog {
  .claim-title {
    font-size: 16px;
    line-height: 21px;
    font-weight: 400;
    font-family: $primaryFont;
    color: $color-white;
    margin-bottom: 27px;
  }
  .profile-box {
    display: flex;
    @media (max-width: 599px) {
      margin-bottom: 45px;
    }
    .check-profile {
      .MuiCheckbox-root {
        padding: 0;
        margin-left: 0;
      }
      margin-right: 18px;
    }
    .cp-details {
      width: 100%;
      .cp-user {
        display: flex;
        margin-bottom: 18px;
        @media (max-width:599px){
          display: block;
        }
        .prof-img {
          width: 134px;
          height: 134px;
        }
        .prof-data {
          margin-left: 26px;
          @media (max-width:599px) {
            margin-left: -26px;
            margin-top: 20px;
          }
          .prof-name {
            font-size: 21.33px;
            line-height: 28px;
            font-weight: 400;
            font-family: $primaryFont;
            color: $color-white;
            margin-bottom: 9px;
          }

          .ndca-bx {
            .ndcafield {
              font-size: 16px;
              line-height: 21px;
              font-weight: 400;
              font-family: $primaryFont;
              color: $color-white;
              margin-bottom: 4px;

              &:last-child {
                margin-bottom: 0;
              }

              span {
                color: #b4babf;
              }
            }

            .nickname {
              span {
                margin-right: 72px;
              }
            }
            .dob {
              span {
                margin-right: 53px;
              }
            }
            .country {
              span {
                margin-right: 89px;
              }
            }
            .addedby {
              display: flex;
              span {
                margin-right: 77px;
              }
              .username {
                color: $color-blue;
                text-decoration: underline;
                cursor: pointer;
              }
            }
          }
        }
      }

      .matchingf-rtxt {
        font-size: 16px;
        line-height: 21px;
        font-weight: 500;
        font-family: $primaryFont;
        color: $color-blue;
        @media (max-width:599px) {
          margin-left: -26px;
        }
      }

      // MATCHING RECORDS
      .matching-rbox {
        margin-top: 9px;
        margin-bottom: 27px;
        padding-right: 24px;
        @media (max-width:599px) {
          margin-left: -26px;
        }
        .matching-record {
          padding: 12px 12px 18px 12px;
          background-color: #202020;
          border-radius: $bRadius;
          margin-bottom: 9px;
          &:last-child {
            margin-bottom: 0;
          }
          .record-top {
            display: flex;
            margin-bottom: 9px;
            .rt-box {
              width: 50%;
              .fight-name {
                font-size: 16px;
                line-height: 21px;
                font-weight: 500;
                font-family: $primaryFont;
                color: $color-white;
              }
              .weight-name {
                font-size: 12px;
                line-height: 16px;
                font-weight: 400;
                font-family: $primaryFont;
                color: #b4babf;
              }

              .fight-txtx {
                font-size: 12px;
                line-height: 16px;
                font-weight: 500;
                font-family: $primaryFont;
                color: $color-white;
              }
            }
            @media (max-width:599px) {
              display: block;
              .rt-box {
                margin-bottom: 15px;
              }
            }
          }

          .record-bottom {
            display: flex;
            .field {
              width: 25%;
              .field-name {
                font-size: 12px;
                line-height: 16px;
                font-weight: 400;
                font-family: $primaryFont;
                color: #b4babf;
                text-transform: uppercase;
              }
              .field-val {
                font-size: 12px;
                line-height: 16px;
                font-weight: 500;
                font-family: $primaryFont;
                color: $color-white;
                text-transform: capitalize;
              }
            }
          }
        }
      }
    }
  }
}

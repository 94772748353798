@import 'src/assets/scss/variable.scss';

.invite-modal-sec{

    .invite-text{
        font-family:$primaryFont;
        font-size: 16px;
        line-height: 21px;
        color: $color-white;
        text-align: center;
    }
    .invite-btn-sec{
        display: flex;
        column-gap: 18px;
        font-family: $authHeaderFont;
        font-size: 21.33px;
        line-height: 26px;
        padding-top: 36px;
    .decline-btn{
        background-color: $color-white;
        color: $color-black;
        padding: 14px;
    }
    .accept-invitation-btn{
        background-color: $color-blue;
        color: $color-white;
        padding: 14px;

    }
}
}

@import 'src/assets/scss/variable.scss';

.tablet-menu {
  padding: 0px 12px;
  position: absolute;
  top: 56px;
  // bottom: 12px;
  height: 40px;
  width: 100%;
  z-index: 1199;
  background: #151515;

  :global(.MuiTabs-flexContainer) {
    justify-content: space-between;
  }

  @media (max-width: $mobileBreakPoint) {
    display: none;
  }
}

.app-menu-btn {
  text-transform: uppercase;
  padding: 12px 8px;

  span {
    display: flex;
    flex-direction: column;
  }
}

.mobile-top-menu {
  position: sticky;
  top: 0;
  padding: 0 12px;
  background: #151515;

  :global(.MuiTabs-flexContainer) {
    justify-content: space-between;
  }

  .icon-active {
    svg path {
      fill: #438ac1;
    }

    span {
      color: #438ac1;
    }
  }
}

@import 'src/assets/scss/variable.scss';
.connection-user.connection-user-sec{
    .all-tab-header{
        color: $color-white;
        font-size: 21.33px;
        line-height: 28px;
        font-family: $authHeaderFont;
    }
    background-color: $blockColor;
    &:hover {
        box-shadow: none;
      }
    .user-image{
    justify-content: space-between;
    .MuiAvatar-root{
        width: 60px;
        height: 60px;
    }
    .user-data-sec{
        display: flex;
        width: 68%;
        .user-name{
            margin-top: 14px;
            .name{
                font-size: 18px;
                line-height: 18px;
            }
            .user-icon {
                padding: 0 12px;
            }
        }
        @media (max-width: 768px) {
            width: 50%;
            justify-content: space-around;
          }
    }
    .button-sec{
        display: flex;
        width: 32%;
        justify-content: space-between;
        .user-f-btn{
            width: 150px !important;
        }
        @media (max-width: 768px) {
            width: 50%;
            justify-content: space-around;
          }
        @media (max-width: 599px) {
            width: auto;
            justify-content: space-around;
          }
    }
    .profile-following-btn{
        padding: 6px 16px;
        color: $color-white;
        background-color: $color-gray-dark;
        font-size: 12px;
        line-height: 18px;
        font-family: $primaryFont;
        text-transform: capitalize;
        border-radius: $bRadius;
        width: 150px !important;

    }
    }

    .profile-btns-container{
        display: flex;
    }
    .see-all-btn{
        padding: 6px 16px;
        color: $color-light-blue;
        background-color: $color-blue;
        font-size: 12px;
        line-height: 18px;
        font-family: $primaryFont;
        text-transform: capitalize;
        border-radius: $bRadius;
        width: 150px;

    }
    .see-all-btn:hover{
        color: $color-light-blue;
        background-color: $color-blue;
    }
}

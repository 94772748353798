@import 'src/assets/scss/variable.scss';

.community-list-view {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .community-btn {
    width: 145px !important;
    font-size: 12px !important;
    line-height: 18px !important;
    font-family: Roboto !important;
    text-transform: capitalize !important;
    background-color: #438ac1 !important;
    color: #d9e8f3 !important;
    margin-right: 18px;
  }
}

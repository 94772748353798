@import 'src/assets/scss/variable.scss';

.admin-app-bar {
  :global(.MuiToolbar-regular) {
    min-height: 54px;
    @media (min-width: 600px) {
      min-height: 54px;
    }
  }

  .app-menu-btn {
    text-transform: uppercase !important;
    position: relative !important;
    margin-left: 50px !important;

    @media (min-width: $mobileBreakPoint) and (max-width: 1100px) {
      margin-right: 0 !important;
      margin-left: 20px !important;
    }
    @media (max-width: 1319px) {
      margin-left: 20px !important;
    }
    @media (min-width: 1320px) and (max-width: 1400px) {
      margin-left: 30px !important;
    }
    @media (min-width: 1400px) and (max-width: 1480px) {
      margin-left: 40px !important;
    }
    @media (min-width: 1600px) {
      margin-left: 60px !important;
    }
    @media (min-width: 1860px) {
      margin-left: 100px !important;
    }
  }
}

.app-bar, .admin-app-bar {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  right: initial !important;
  bottom: initial !important;
  padding-right: 0 !important;
}

.app-bar-cm-user {
  position: relative !important;
  margin-left: 30px !important;
}

.app-bar-searchbtn {
  position: relative !important;
  padding: 5px !important;
  margin-right: 17px !important;
  margin-left: 50px !important;
  @media (max-width: 1400px) {
    margin-left: 10px !important;
  }
  @media (min-width: 1400px) and (max-width: 1480px) {
    margin-left: 40px !important;
  }
  @media (min-width: 1860px) {
    margin-left: 150px !important;
  }
}

.app-bar-notification-btn {
  position: relative !important;
  padding: 5px !important;
  margin-right: 12px !important;
  //HIDE-MVP: CM-78
  //added to hide search
  margin-left: 96px !important;
  @media (min-width: 1860px) {
    margin-left: 246px !important;
  }
  //
}

.app-bar-notification-btn.message-icon {
  //HIDE-MVP: CM-78
  //added to hide search
  margin-left: 0 !important;
  //
  .msg-icon {
    width: 25px !important;
    height: 25px !important;
  }
}

.cm-user-title {
  text-transform: uppercase !important;
  font-size: 21px !important;
  line-height: 23px !important;
  font-family: $authHeaderFont !important;
  color: $color-white !important;
  display: flex !important;
  align-items: center !important;
  span {
    color: $color-blue !important;
  }
}

.my-option-menu {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
  :global(.MuiListItemText-primary) {
    font-size: 16px !important;
    line-height: 20px !important;
    font-family: $authHeaderFont !important;
    color: $color-white !important;
  }
}

@import 'src/assets/scss/variable.scss';

.Acoount-setting-right-sec{
    color: $color-white;
    .General-setting-sec{
        .header-sec{
            padding: 24px 25px;
            border-bottom: $blockBorder;
            .header-text{
                font-family: $primaryFont;
                font-size: 21.33px;
                color: $color-white;
                line-height: 28px;
            }
        }
        .content-sec{
            padding: 13px 25px;
            font-family: $primaryFont;
            font-size: 15px;
            line-height: 20px;
            display: flex;
            flex-direction: column;
            gap: 10px;
            .content-title{

            }
            .content-name{

            }
            .edit-link{
                color: $color-blue;
                text-decoration: underline;
                text-underline-offset:2px;
                cursor: pointer;
            }
        }
    }
}

@import 'src/assets/scss/variable.scss';

.referral-banner {
  min-height: 300px;
  display: flex;
  align-items: center;
  background-image: url(../../../../assets/images/banner/referral-banner.png);
  .combat-container {
    margin: 0 51px 0 92px !important;
    padding-bottom: 0 !important;
  }
  .banner-head {
    font-family: $authHeaderFont;
    font-size: 67.42px;
  }
}

@import 'src/assets/scss/variable.scss';

.fight-confirm-box {
  padding: 18px;
  padding-bottom: 45px;
  background-color: $blockColor;
  border: $blockBorder;
  border-radius: $bRadius;

  .confirm-title {
    font-size: 37.92px;
    line-height: 47px;
    font-weight: 400;
    font-family: $authHeaderFont;
    color: $color-white;
    margin-bottom: 18px;
  }
  .box-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom:1px solid $color-white;

    .box-title {
      font-size: 28.45px;
      line-height: 36px;
      font-weight: 400;
      font-family: $authHeaderFont;
      color: $color-white;
    }
    .MuiIconButton-root {
      padding: 0;
      .pencil-icon{
        width: 17px;
        height: 17px;
      }
    }

  }

  .fight-cc-bx {
    margin-top: 27px;
  }

  .event-cover-confirm {
    margin-top: 10px;
    margin-bottom: 13px;
    // width: 100%;
    // height: 300px;
    width: 245px;
    height: 114px;
    border-radius: $bRadius;
  }

  .event-field {
    margin-top: 18px;
    font-size: 21.33px;
    line-height: 26px;
    font-weight: 500;
    color: $color-white;
    font-family: $authHeaderFont;
    text-transform: uppercase;
  }
  .event-field-value {
    font-size: 16px;
    line-height: 21px;
    font-weight: 400;
    font-family: $primaryFont;
    color: $color-white;
    word-break: break-word;
  }
  .event-field-grid{
    padding-top: 9px;
  }
  .event-field-title{
    font-weight: 1000;
  }
  .event-user-profile{
    display: flex;
    padding: 5px 0;
    .event-profile{
      width: 30px;
      height: 30px;
      margin-right: 18px;
    }
    .event-field-value{
      align-self: center;
    }
  }
  .event-field-user{
    padding-bottom: 9px;
  }
  .card-confirm-tab {
    margin-top: 18px;
    margin-bottom: 27px;
    display: flex;
    .card-btn {
      font-size: 18px;
      line-height: 22px;
      font-weight: 400;
      font-family: $authHeaderFont;
      padding: 10px 18px;
      color: #e5e5e5;
    }
    .main-card {
      margin-right: 9px;
    }
    .active {
      background-color: $color-blue;
      color: $color-white;
    }
  }
}

@import 'src/assets/scss/variable.scss';

.Acoount-set-left-sec{
    .header-text{
        font-family: $primaryFont;
        font-size: 28.45px;
        color: $color-white;
        line-height: 38px;
    }
    .account-setting-details{
        margin-top: 19px;
        .account-setting{
            padding: 7px 16px;
            display: flex;
            border-radius: 5px;
            cursor: pointer;
            .setting-name{
            font-family: $primaryFont;
            font-size: 16px;
            color: $color-white;
            line-height: 21px;
            margin-left: 9px;
            }
        }
        .selected-setting{
            background-color: $color-msg-left;
        }
    }
}

@import 'src/assets/scss/variable.scss';

.admin-app-bar {
  :global(.MuiToolbar-regular) {
    min-height: 54px;
    @media (min-width: 600px) {
      min-height: 54px;
    }
  }
}

.app-menu-btn {
  position: relative !important;
  margin-left: 50px !important;
  margin-right: 25px !important;

  &:last-child {
    margin-right: 0 !important;
  }

  @media (min-width: $mobileBreakPoint) and (max-width: 1100px) {
    margin-right: 0 !important;
    margin-left: 20px !important;
  }
  @media (max-width: 1319px) {
    margin-left: 20px !important;
  }
  @media (min-width: 1320px) and (max-width: 1400px) {
    margin-left: 30px !important;
  }
  @media (min-width: 1400px) and (max-width: 1480px) {
    margin-left: 40px !important;
  }
  @media (min-width: 1600px) {
    margin-left: 60px !important;
  }
  @media (min-width: 1860px) {
    margin-left: 100px !important;
  }
}

.app-bar-notification-btn {
  position: relative !important;
  padding: 5px !important;
  margin-right: 12px !important;
  //HIDE-MVP: CM-78
  //added to hide search
  margin-left: 96px !important;
  @media (min-width: 1860px) {
    margin-left: 246px !important;
  }
  //
}

.app-bar-searchbtn {
  position: relative !important;
  padding: 5px !important;
  margin-right: 17px !important;
  margin-left: 50px !important;
  @media (max-width: 1400px) {
    margin-left: 10px !important;
  }
  @media (min-width: 1400px) and (max-width: 1480px) {
    margin-left: 40px !important;
  }
  @media (min-width: 1860px) {
    margin-left: 150px !important;
  }
}

.app-bar-notification-btn.message-icon {
  //HIDE-MVP: CM-78
  //added to hide search
  margin-left: 0 !important;
  //
  .msg-icon {
    width: 25px !important;
    height: 25px !important;
  }
}

.app-bar-cm-user {
  position: relative !important;
}

.logo-anchor {
  display: flex;
  align-items: center;
}

.cm-user-title {
  text-transform: uppercase !important;
  font-size: 21px !important;
  line-height: 23px !important;
  font-family: Staatliches, Alumni Sans, sans-serif !important;
  color: #ffffff !important;
  display: flex !important;
  align-items: center !important;
  margin-left: 15px !important;

  span {
    color: $color-blue !important;
  }
}

.customized-menu-class {
  :global(.MuiPopover-paper::-webkit-scrollbar) {
    display: none;
  }
}

.my-option-menu {
  padding-top: 3px !important;
  padding-bottom: 3px !important;

  :global(.MuiListItemText-primary) {
    font-size: 16px;
    line-height: 20px;
    font-family: $authHeaderFont;
    color: $color-white;
  }
}

.user-action-modalbody {
  text-transform: uppercase;
  margin: -8px 0px;
  padding: 17px 5px 8px 13px;

  .org-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 6px;

    .org-data {
      display: flex;
      align-items: center;

      .org-img {
        width: 36px;
        height: 36px;
      }

      p {
        font-size: 16px;
        line-height: 21px;
        font-weight: 400;
        font-family: $primaryFont;
        color: $color-white;
        margin-left: 6px;
        cursor: pointer;
      }
    }
  }

  .neworg-btn {
    font-size: 12px;
    line-height: 18px;
    text-transform: capitalize;
    font-weight: 400;
    font-family: $primaryFont;
    background-color: $color-blue;
    color: #d9e8f3;
    margin-top: 12px;
    margin-bottom: 27px;

    &:hover {
      background-color: $color-blue;
      color: #d9e8f3;
    }
  }
}

.site-banner {
  padding: 5px;
  @media (max-width: $mobileBreakPoint) {
    display: none;
  }

  .banner-title {
    text-align: center;
    font-size: 20px;
    background: #438ac1;
  }
}

.app-bar, .admin-app-bar {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  right: initial !important;
  bottom: initial !important;
  padding-right: 0 !important;
}

.modal-h {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 9px;

  p {
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    font-family: $authHeaderFont;
    color: $color-white;
  }

  .seeall-btn {
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    font-family: $authHeaderFont;
    color: $color-blue;
    padding: 0;
    min-width: 0;
  }

  .wbtn {

    background-color: transparent !important;
    color: $color-white !important;

    &:hover {
      background-color: transparent !important;
      color: $color-white !important;
    }
  }
}

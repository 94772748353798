@import 'src/assets/scss/variable.scss';

.combatant-details-sec{
  margin-top: 24px;
    .add-com-btn-grid{
        text-align: end;
        .add-combatant-btn{
            background-color: $color-blue;
            color: $color-white;
            font-size: 12px;
            line-height: 18px;
            font-family: $primaryFont;
            text-transform: capitalize;
            &:hover {
              background-color: $color-blue;
              color: $color-white;
            }
          }
    }
    .combatant-vs-btn-sec{
      display: flex;
      border-top: 2px solid $color-blue;
      padding: 12px;
      // margin: 0 12px;
      .combatant-vs-text{
        font-family: $authHeaderFont;
        color: $color-white;
        font-size: 21.33px;
        line-height: 26px;
        width: 100%;
        text-align: center;
      }
    }
      .check-box{
        padding: 0 9px;
      }
      .event-bg-sec{
        width: 100%;
        padding: 12px 12px;
        background:$color-light-black;
        margin: 10px 0px;
        .event-bg-grid{
          display: flex;
          justify-content: space-between;
          padding-bottom: 9px;
          .date-bg-text{
            color: $color-pearl;
            font-size: 16px;
            line-height: 21px;
            font-family: $primaryFont;
          }
          .delete-date{
            padding: 0 5px;
          }
        }
      }
      .pr-12{
        padding-right: 12px;
      }
}

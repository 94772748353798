@import 'src/assets/scss/variable.scss';

.community-badge-box {
  padding: 18px;
  background: $blockColor;
  border: $blockBorder;
  border-radius: $bRadius;
  margin-top: 9px;

  .badge-box-title {
    font-size: 16px;
    line-height: 20px;
    font-family: $authHeaderFont;
    color: $color-white;
    margin-bottom: 9px;
  }

  .badges-container {
    display: flex;
    .badge-block {
      max-width: 45px;
      height: auto;
      text-align: center;

      .badge-icon {
        width: 45px;
        height: 45px;
      }

      .verified-badge-text {
        margin-top: 4px;
        font-size: 12px;
        line-height: 15px;
        font-weight: 400;
        font-family: $primaryFont;
        color: $color-white;
      }
    }
  }

  .no-community-text {
    line-height: 16px;
    text-align: center;
    color: $color-spanish-gray;
    font-size: 12px;
    font-family: $primaryFont;
    font-weight: 400;
    margin-top: 14px;
    margin-bottom: 7px;
    width: 100%;
  }
}

@import 'src/assets/scss/variable.scss';

.footer-main {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;

  background-color: #151515 !important;
  color: $color-white !important;
  text-align: center;
  padding: 0 5px 5px 5px;
  border-radius: 0;
  z-index: 9900;

  .footer-container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .language-dd {
      width: 150px;
      position: inherit;
      right: 0;
      bottom: 12px;

      :global(.MuiSelect-root) {
        display: flex;
        justify-content: space-around;
        padding-top: 5px;
        padding-bottom: 5px;
        // .svg-icon {
        //   g {
        //     width: 25px;
        //     height: 25px;
        //   }
        // }
      }

      .flag-img {
        width: 25px;
        height: 25px;
      }

      .flag-name {
        align-self: center;
      }

      .text-field-grid {
        padding: 0;
      }
    }

    .cm-user-number {
      visibility: hidden;
      @media (max-width: $mobileBreakPoint) {
        visibility: visible;
        position: inherit;
        padding: 5px 16px;
        left: 0;
        bottom: 0;
        .cm-user-title {
          text-transform: uppercase;
          font-size: 18px;
          line-height: 23px;
          font-family: $authHeaderFont;
          color: $color-white;
          display: flex;
          align-items: center;

          span {
            color: $color-blue;
          }
        }
      }
    }

    .cm-user-title {
      text-transform: uppercase;
      font-size: 21px;
      line-height: 23px;
      font-family: $authHeaderFont;
      color: $color-white;
      display: flex;
      align-items: center;

      span {
        color: $color-blue;
      }
    }
  }
}

.language-selector__icon {
  width: 40px;
  padding: 4px;
  margin-right: 8px;
}

.language-selector {
  .language-selector__icon {
    height: 25px;
  }
}

.sitelanguage-paper {
  bottom: 40px;
  top: auto !important;
}

@import 'src/assets/scss/variable.scss';


    .new-events-sec{
        display: flex;
        justify-content: space-between;
        .new-event-left-sec{
            width: 35%;
            text-align: left;
            .new-event-text{
                font-size: 37.92px;
                line-height: 47px;
                color: $color-white;
                font-family: $authHeaderFont;
                text-align: left ;
            }
            .events-type-sec{
                margin: 18px 0 44px;
            }
            .new-event-next-btn{
                // min-width: 362px;
                background-color: $color-blue;
                color: $color-white;
                font-size: 21.33px;
                line-height: 26px;
                font-family: $primaryFont;
                font-weight: 400;
                text-transform: capitalize;
                &:hover {
                  background-color: $color-blue;
                  color: $color-white;
                }
            }
            .white-btn{
                background-color: $color-white;
                color: $color-black;
            }

            .MuiList-padding {
                padding: 0;
                .MuiListItem-gutters {
                    padding: 0;
                }
            }
            .category-amount-sec{
                width: 100%;
                padding: 12px;
            }
            .super-tooltip-sec{
                display: flex;
                justify-content: space-between;
            }
            @media (max-width:959px) {
                width: 50%;
            }
            @media (max-width:599px) {
                width: 100%;
            }
        }
        .new-event-right-sec{
            width: 65%;
            .new-events-right-image-sec{
                position: relative;
                .image-1{
                    width: 449.39px;
                    height: 449.39px;
                    position: absolute;
                    right: 0;
                    @media (max-width:1600px) {
                        width: 300px;
                        height: 300px;
                    }
                }
                .image-2{
                    width: 357.83px;
                    height: 357.83px;
                    position: absolute;
                    left: 0;
                    top: 340px;
                    @media (max-width:1600px) {
                        width: 200px;
                        height: 200px;
                        position: absolute;
                        left: 100px;
                        top: 243px;
                    }
                }
                .image-3{
                    width: 247.79px;
                    height: 247.79px;
                    position: absolute;
                    right: 170px;
                    top: 620px;
                    @media (max-width:1600px) {
                        width:150px;
                        height: 150px;
                        position: absolute;
                        right: 130px;
                        top: 400px;
                    }
                }
            }
            @media (max-width:1600px) {
               width: 50%;
            }
            @media (max-width:1200px) {
                width: 55%;
             }
             @media (max-width:1024px) {
                width: 65%;
             }
             @media (max-width:959px) {
                display: none;
             }
        }
    }
    .tooltip-data{
        width: 250px;
        font-size: 15px;
        line-height: 18px;
        font-family: $primaryFont;
        .tooltip-header{
            font-weight: 800;
        }
    }

@import 'src/assets/scss/variable.scss';

.badge-form-main {
  .MuiAvatar-root {
    width: 115px;
    height: 115px;
    border-radius: 0;
  }
  .camera-icon {
    position: absolute;
    right: 0;
    top: 0;
  }
  .p-0 {
    padding: 0;
  }
  .form-content {
    text-align: center;
    form {
      padding-top: 30px;
      text-align: left;
    }
    .type-box{

        .restriction-txt {
          font-size: 16px;
          line-height: 21px;
          font-weight: 400;
          font-family: $primaryFont;
          color: $color-white;
          margin-bottom: 9px;
        }

        .restriction-chips {
          display: flex;
          .rest-chip {
            height: 21px;
            margin-top: 9px;
            margin-right: 3px;
            color: $color-spanish-gray;
            background-color: $color-gray-dark;
            display: flex;
            align-items: center;
            @media (max-width: 599px) {
              margin-right: 9px;
            }
            .MuiChip-iconSmall {
              width: 13px;
              height: 13px;
            }
            .MuiChip-labelSmall {
              font-size: 12px;
              line-height: 16px;
              font-weight: 400;
              font-family: $primaryFont;
              position: relative;
              top: -1px;
            }
          }

          .checked-chip {
            background-color: $color-blue !important;
            color: $color-white !important;
          }
        }
    }
  }
}
.badge-main {
  .MuiButton-outlined {
    border-color: $color-blue;
    color: $color-blue;
  }
}
.mr-10 {
  margin-right: 10px !important;
}

@import 'src/assets/scss/variable.scss';

.create-event-box {
  position: relative;
}
.restriction-box {
  // margin-top: 43px;

  .restriction-txt {
    font-size: 16px;
    line-height: 21px;
    font-weight: 400;
    font-family: $primaryFont;
    color: $color-white;
    margin-bottom: 9px;
  }

  .restriction-chips {
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;
    &::-webkit-scrollbar {
      display: none;
    }
    .rest-chip {
      height: 21px;
      margin-top: 9px;
      margin-right: 3px;
      color: $color-spanish-gray;
      background-color: $color-gray-dark;
      display: flex;
      align-items: center;
      @media (max-width: 599px) {
        margin-right: 9px;
      }
      .MuiChip-iconSmall {
        width: 13px;
        height: 13px;
      }
      .MuiChip-labelSmall {
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        font-family: $primaryFont;
        position: relative;
        top: -1px;
      }
    }

    .checked-chip {
      background-color: $color-blue !important;
      color: $color-white !important;
    }
  }
}

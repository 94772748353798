@import 'src/assets/scss/variable.scss';

.changeemail-box{
    .otp-text-sec{
        font-family: $primaryFont;
        font-size: 16px;
        color: $color-white;
        line-height: 21px;
        margin-bottom: 24px;
        text-align: center;
    }
    .resend-otp-link{
        font-family: $primaryFont;
        font-size: 18px;
        line-height: 24px;
        color: $color-blue;
        text-decoration: underline;
        text-underline-offset:2px;
        cursor: pointer;
    }
    .change-btn{
        margin-top: 36px;
    }
}

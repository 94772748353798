@import 'src/assets/scss/variable.scss';

// .right-organisation-block {
//   width: 100%;
.myorg-right, .org-mobile-page {
  .my-right-top {
    padding: 18px;
    width: 100%;
    background: $blockColor;
    border: $blockBorder;
    border-radius: $bRadius;
    .org-share {
      background: #438ac1;
      padding: 6px;
      position: absolute;
      right: 10px;
      // top: 10px;
      @media (max-width:$mobileBreakPoint) {
        top:auto
      }
    }
    // .mobile-org-share {
    //   @media (max-width: 480px) {
    //     background: #438ac1;
    //     padding: 6px;
    //     position: relative;
    //   }
    // }
    @media (max-width: 599px) {
      padding: 12px;
    }
    .social-icons {
      display: flex;
      margin-top: 9px;
      .MuiGrid-container {
        @media (max-width: 599px) {
          justify-content: center;
        }
        @media (max-width:$mobileBreakPoint) {
          justify-content: start;
        }
      }
      .social-link {
        margin-right: 6px;
        img {
          max-width: 18px;
          max-height: 18px;
        }
        &:last-child {
          margin-right: 0px;
        }
      }
    }
    .my-right-img {
      width: 100%;
      display: flex;
      @media (max-width: 599px) {
        display: block;
      }
      @media (max-width:$mobileBreakPoint) {
        display: flex;
      }
      .my-right-avatar {
        .right-img {
          // position: relative;
          // border-radius: 50%;
          @media (max-width: 599px) {
            text-align: center;
            margin-bottom: 14px;
            display: flex;
            justify-content: center;
          }
          .MuiIconButton-root {
            padding: 0;
          }
          .MuiAvatar-root {
            width: 115px;
            height: 115px;
          }
          .camera-icon {
            position: absolute;
            right: 0;
            bottom: 0;
          }
        }
      }

      .myright-names {
        padding-left: 25px;
        @media (max-width: 599px) {
          padding-left: 0;
          text-align: center;
        }
        @media (max-width:$mobileBreakPoint) {
          margin-left: 20px;
        }
        .myright-wildcard {
          font-size: 16px;
          line-height: 20px;
          font-family: $authHeaderFont;
          color: $color-blue;
          margin-top: 7px;
          word-break: break-word;
          @media (max-width: 599px) {
            font-size: 14px;
            line-height: 18px;
            text-align: center;
          }
          @media (max-width:$mobileBreakPoint) {
            text-align: start;
          }
        }
        .right-username {
          font-size: 30px;
          line-height: 38px;
          font-family: $authHeaderFont;
          color: $color-white;
          margin-bottom: 4px;
          word-break: break-word;
          @media (max-width: 599px) {
            font-size: 26px;
            line-height: 32px;
            text-align: center;
          }
          @media (max-width:$mobileBreakPoint) {
            text-align: start;
          }
        }
        .member-since {
          display: flex;
          align-items: center;
          font-size: 12px;
          line-height: 15px;
          font-family: $authHeaderFont;
          color: #adbdc2;
          @media (max-width: 599px) {
            justify-content: center;
          }
          @media (max-width:$mobileBreakPoint) {
            justify-content: left;
          }
        }
      }
    }

    .social-right-edit-actions {
      position: relative;
      padding-top: 18px;
      display: flex;
      justify-content: space-between;

      .edt-profile-btn {
        color: $color-light-blue;
        background-color: $color-blue;
        // width: 49%;
        font-size: 12px;
        line-height: 18px;
        font-family: $primaryFont;
        text-transform: capitalize;
        border-radius: $bRadius;
        @media (max-width: 374px) {
          padding: 6px 9px;
        }
      }
      .connection-have-btn {
        padding: 7px 0px;
        color: $color-white;
        background-color: $color-gray-dark;
        font-size: 12px;
        line-height: 18px;
        font-family: $primaryFont;
        text-transform: capitalize;
        border-radius: 3px;
        @media (max-width: 374px) {
          padding: 6px 9px;
        }
      }
    }

    .right-prof-counts {
      position: relative;
      display: flex;
      justify-content: space-around;
      padding: 9px 0;
      margin-top: 15px;
      border-top: $blockBorder;
      border-bottom: $blockBorder;

      .social-count-bx {
        text-align: center;
        .social-count {
          font-size: 16px;
          line-height: 21px;
          font-weight: 500;
          font-family: $primaryFont;
          color: $color-blue;
        }
        .social-act-name {
          font-size: 12px;
          line-height: 16px;
          font-weight: 400;
          font-family: $primaryFont;
          color: #6a6a6a;
        }
      }
    }
    .address-block {
      padding-top: 14px;
      .address-title {
        font-size: 16px;
        line-height: 20px;
        font-family: $authHeaderFont;
        color: $color-white;
      }
      .address {
        font-size: 12px;
        line-height: 18px;
        font-family: $primaryFont;
        color: $color-white;
      }
    }
  }
}
// }
.cffc-data-container {
  position: relative;
  max-height: 220px;
  overflow-y: scroll;
  margin-top: 5px;
}

.no-data-txt {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  font-family: $primaryFont;
  text-align: center;
  color: $color-spanish-gray;
  margin-top: 16px;
  margin-bottom: 7px;
}
.activity-tab {
  .manage-member-btn {
    width: 100%;
    border-radius: $bRadius;
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    font-family: $primaryFont;
    background-color: $color-blue;
    color: $color-white;
    margin-bottom: 5px;
    text-transform: capitalize;
    &:hover {
      background-color: $color-blue;
    }
  }
}

@import 'src/assets/scss/variable.scss';

.event-details-cover {
  padding: 18px;
  background-color: $blockColor;
  border: $blockBorder;
  border-radius: $bRadius;

  .event-details-heading {
    font-size: 37.92px;
    line-height: 47px;
    font-weight: 400;
    font-family: $authHeaderFont;
    color: $color-white;
    margin-bottom: 18px;
  }

  // EVENT COVER
  .event-cover-box {
    border-top: $blockBorder;
    padding-top: 18px;
    .preview-cover {
      width: 100%;
      height: 300px;
      border-radius: $bRadius;
      margin-bottom: 18px;
      img {
        width: 100%;
      }
      @media (max-width: 599px) {
        height: 200px;
      }
    }
    .camera-icon {
      position: absolute;
      top: 12px;
      right: 12px;
    }
    .upload-button {
      width: 100%;
      border-radius: $bRadius;
      padding: 146px 0px;
      font-size: 12px;
      line-height: 16px;
      font-weight: 400;
      font-family: $primaryFont;
      text-transform: capitalize;
      background-color: #1b1b1b;
      border: $blockBorder;
      color: #9d9d9d;
    }
    .MuiIconButton-root {
      width: 100%;
      padding: 0;
    }
    .MuiButton-endIcon {
      position: absolute;
      top: 12px;
      right: 12px;
    }
  }

  // EVENT DETAILS FORM
  .event-details-form {
    margin-top: 24px;
    margin-bottom: 42px;
    .event-titles{
      display: flex;
      padding-bottom: 0;
      padding-top: 36px;
      .event-titles-name{
        font-size: 21.33px;
        line-height: 26px;
        font-family: $primaryFont;
        font-weight: 400;
        padding-left: 15px;
        align-self: center;
      }
    }
    .event-titles:first-child{
      padding-top: 0px;
    }
    .add-date-btn-sec{
      text-align: end;
      .add-date-btn{
        background-color: $color-blue;
        color: $color-white;
        font-size: 12px;
        line-height: 18px;
        font-family: $primaryFont;
        font-weight: 400;
        text-transform: capitalize;
        &:hover {
          background-color: $color-blue;
          color: $color-white;
        }
      }
    }
    .event-bg-sec{
      width: 100%;
      padding: 20px 12px;
      background:$color-light-black;
      margin: 5px 10px 0;
      .event-bg-grid{
        display: flex;
        justify-content: space-between;
        .date-bg-text{
          color: $color-pearl;
          font-size: 16px;
          line-height: 21px;
          font-family: $primaryFont;
        }
        .delete-date{
          padding: 0 5px;
        }
        .hide-date{
          display: none;
        }
      }
    }

  }
  @media (max-width: 599px) {
    .date-birth-field {
      display: block !important;
      .date-field {
        padding-right: 0px !important;
      }
      .age-field {
        padding-left:  0px !important;
        padding-top: 5px;
      }
    }
  }
}

@import 'src/assets/scss/variable.scss';

.discipline-table-container {
  position: relative;
  display: flex;
  margin-bottom: 9px;

  .owner-discipline {
    min-width: 586px;
    max-width: 586px;
    @media (max-width: 1279px) {
      min-width: 0;
    }
  }

  .MuiTableRow-root {
    display: flex;
  }

  ::-webkit-scrollbar {
    height: 6px;
  }

  ::-webkit-scrollbar-track {
    background-color: $color-black-very-light;
    border-radius: $bRadius;
  }

  ::-webkit-scrollbar-thumb {
    background: $color-blue;
    border-radius: $bRadius;
  }

  .MuiTableCell-sizeSmall {
    padding: 2px;
  }

  .MuiTableCell-root {
    border-bottom: none;
  }

  .MuiTable-root {
    min-width: 657px;
    margin-top: 9px;
    margin-bottom: 22px;

    @media (max-width: 599px) {
      min-width: 722px;
      margin-bottom: 12px !important; // addmin has common class
    }
  }

  .MuiTableCell-head {
    font-size: 16px;
    line-height: 21px;
    font-weight: 500;
    font-family: $primaryFont;
    color: #b4babf;
    @media (max-width: 599px) {
      font-size: 14px !important; // addmin has common class
    }
  }

  .MuiTableBody-root {
    padding-top: 9px;
  }

  .MuiTableCell-body {
    font-size: 16px;
    line-height: 21px;
    font-weight: 400;
    font-family: $primaryFont;
    color: $color-white;
    // white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ellipsis;
    @media (max-width: 599px) {
      font-size: 14px !important; // addmin has common class
    }
  }

  .style-cell {
    // width: 120px;
    width: 250px;
  }

  .rank-cell {
    // width: 71px;
    width: 100px;
  }

  .gym-cell {
    width: 114px;
  }

  .approve-cell {
    width: 119px;
  }

  .date-generated-cell,
  .endorsement-cell {
    width: 115px;
  }

  .endorsement-cell {
    .MuiAvatar-root {
      width: 21px;
      height: 21px;
      font-size: 12px;
      line-height: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $color-blue;
      color: $color-white;
    }
  }

  .discipline-actions {
    background-color: $blockColor;
    position: absolute;
    right: -15px;
    .edit-delete {
      display: flex;
    }
    .MuiIconButton-root {
      margin: 0px 4px;

      &:first-child {
        margin-left: 11.5px;
      }
      &:last-child {
        margin-right: 0;
      }
    }

    @media (max-width: 599px) {
      position: relative;
    }
  }
}

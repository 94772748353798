@import 'src/assets/scss/variable.scss';

.right-ad-block {
  margin-top: 9px;
  width: 100%;
  height: 100%;
  // height: 126px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $color-blue;
  color: $color-black-very-light;
  border-radius: $bRadius;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
  font-family: $authHeaderFont;
  .right-ad-banner {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
}

@import 'src/assets/scss/variable.scss';

.lower-upper-values{
    padding: 16px;
    .apply-filter-sec{
        text-align: end;
        .filter-btn{
            background: $color-blue;
            color: $color-white;
            font-size: 12px;
            text-transform: unset;
            padding: 5px 15px;
            @media (max-width: $mobileBreakPoint) {
              margin-bottom: 15px;
            }
          }
    }

}
.dropdown-header-sec{
  padding: 16px 16px 0px 16px;
  color: $color-white;
  .label-header{
    font-size: 18px;
    line-height: 20px;
    padding-bottom: 5px;
  }
  .label-text{
    font-size: 14px;
    line-height: 20px;
  }
}

@import 'src/assets/scss/variable.scss';

.bpdtr-box {
  background-color: $blockColor;
  border: $blockBorder;
  border-radius: $bRadius;
  padding: 18px;
  margin-bottom: 9px;
  display: flex;
  @media (max-width: 599px) {
    padding: 12px;
  }
  .Mui-selected {
    background-color: $color-blue;
  }

  .MuiTab-wrapper {
    flex-direction: row;
  }

  .MuiTab-textColorInherit {
    opacity: 1;
  }

  .MuiTab-labelIcon {
    padding: 0;
    min-height: 42px;

    .MuiTab-wrapper > svg {
      margin-bottom: 0px;
      margin-right: 10.1px;
    }
  }
  .MuiTabs-indicator {
    display: none;
  }
  .MuiTabs-root {
    min-height: 42px;
  }
  .MuiTab-root {
    font-size: 18px;
    line-height: 22px;
    color: $color-white;
    font-family: $authHeaderFont;
    border-radius: $bRadius;
    padding: 0px 19px;

    @media (min-width: 600px) {
      min-width: 0px;
    }
    @media (max-width: 599px) {
      font-size: 16px;
      line-height: 20px;
      padding: 0px 12px;
      min-height: 34px;
    }
  }
}

.post-container-box {
  padding: 10px 18px 18px 18px;
  border: $blockBorder;
  border-radius: $bRadius;
  background-color: $blockColor;
}
.no-post-text {
  font-size: 12px !important;
  line-height: 16px !important;
  font-weight: 400 !important;
  font-family: $primaryFont !important;
  text-align: center;
  color: $color-spanish-gray;
  margin-top: 16px;
  margin-bottom: 7px;
}

@import 'src/assets/scss/variable.scss';

.edit-restrictions {
  .restriction-txt {
    font-size: 16px;
    line-height: 21px;
    font-weight: 400;
    font-family: $primaryFont;
    color: $color-white;
    // margin-bottom: 9px;
  }
  .filter-tags {
    margin: 50px 0px;
    display: flex;

    .filter-chip {
      height: 21px;
      margin-right: 3px;
      margin-bottom: 3px;
      color: $color-spanish-gray;
      background-color: $color-gray-dark;
      display: flex;
      align-items: center;
      @media (max-width: 599px) {
        margin-right: 9px;
      }
      .MuiChip-iconSmall {
        width: 13px;
        height: 13px;
      }
      .MuiChip-labelSmall {
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        font-family: $primaryFont;
        position: relative;
        top: -1px;
        padding-left: 9px;
        padding-right: 9px;
      }
    }

    .checked-chip {
      background-color: $color-blue !important;
      color: $color-white !important;
    }
  }
}

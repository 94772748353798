@import 'src/assets/scss/variable.scss';

.community-event-card {
  background: #202020 !important;
  border: 1px solid #2e353a;
  .com-event-image {
    height: 146px;
    cursor: pointer;
    width: 100%;
  }

  .draft-event {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 28.45px;
    line-height: 36px;
    font-weight: 400;
    font-family: $authHeaderFont;
    color: $color-white;
  }

  .MuiCardContent-root {
    padding: 18px;
  }
  .MuiCardContent-root:last-child {
    padding: 18px;
  }

  .event-timing {
    font-size: 12px;
    line-height: 18px;
    font-weight: 700;
    font-family: $primaryFont;
    color: $color-white;
  }
  .event-tag-box {
    margin-top: 4px;
    margin-bottom: 2px;

    .event-tag {
      min-width: 0;
      height: 20px;
      padding-top: 1px !important;
      padding: 0px 5px;
      font-size: 12px;
      font-family: $authHeaderFont;
      font-weight: 400;
      color: $color-black-very-light;
      background-color: $color-white;
      border-radius: $bRadius;
      cursor: text;
    }
  }

  .event-title {
    font-size: 18px;
    line-height: 21px;
    font-weight: 700;
    font-family: $primaryFont;
    color: $color-white;
    // margin-bottom: 9px;
    cursor: pointer;
  }

  .event-status {
    font-size: 12px;
    line-height: 16px;
    font-family: $primaryFont;
    font-weight: 400;
    color: #b4babf;
    padding-bottom: 9px;
  }

  .MuiCardActions-root {
    padding: 18px;

    .draft-edit-event {
      padding: 0;
      height: 45px;
      background-color: $color-blue;
    }
    .edit-event-btn {
      background-color: $color-white;
      border-radius: $bRadius;
      font-size: 16px;
      line-height: 21px;
      font-weight: 400;
      font-family: $primaryFont;
      color: #101010;
      text-transform: inherit;
      border-radius: $bRadius;
    }
    .owned-editdetails {
      padding: 13.5px 0;
      font-size: 16px;
      line-height: 21px;
      font-weight: 400;
      font-family: $primaryFont;
      color: #101010;
      background-color: $color-white;
      text-transform: capitalize;
      width: 100%;
      border-radius: $bRadius;
    }
    .owned-edit {
      padding: 0;
      height: 48px;
      background-color: $color-blue;
      border-radius: $bRadius;
    }
  }
}

@import 'src/assets/scss/variable.scss';

.fr-cover {
  padding: 18px;
  background-color: $blockColor;
  border: $blockBorder;
  border-radius: $bRadius;
  height: 100%;

  @media (max-width: 599px) {
    .date-birth-field {
      display: block !important;
      padding: 0px 12px !important;
      .date-field {
        padding: 4.5px 0px !important;
      }
      .age-field {
        padding: 4.5px 0px !important;
      }
    }
  }

  .fr-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 37.92px;
    line-height: 47px;
    font-weight: 400;
    font-family: $authHeaderFont;
    color: $color-white;
    margin-bottom: 18px;
    text-transform: uppercase;
  }
}

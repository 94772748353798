@import 'src/assets/scss/variable.scss';

.confirm-cover {
  .cfield {
    padding-bottom: 27px;
    &:last-child {
      padding-bottom: 0px;
    }
    .fd-h1 {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: $blockBorder;
      .fd-title {
        font-size: 28.45px;
        line-height: 36px;
        font-weight: 400;
        font-family: $authHeaderFont;
        color: $color-white;
      }
      .MuiIconButton-root {
        padding: 0;
      }
    }
    .field-name {
      margin-top: 18px;
      font-size: 12px;
      line-height: 18px;
      font-weight: 500;
      font-family: $primaryFont;
      color: #b4babf;
      text-transform: uppercase;
    }
    .field-val {
      font-size: 16px;
      line-height: 21px;
      font-weight: 400;
      font-family: $primaryFont;
      color: $color-white;
      text-transform: capitalize;
    }
    .team-field {
      padding-top: 14px;
      padding-bottom: 22px;
      .team-i {
        margin-bottom: -8px;
        font-size: 16px;
        line-height: 18px;
        font-weight: 500;
        color: #b4babf;
        text-transform: uppercase;
      }
    }
  }
}

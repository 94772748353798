@import 'src/assets/scss/variable.scss';

.create-community-show-box-container {
  padding-bottom: 18px;
  border-bottom: $blockBorder;

  .create-community-show-box {
    padding: 16px;

    background-color: $blockColor;
    border: $blockBorder;
    border-radius: $bRadius;
    display: flex;
    align-items: center;

    @media (max-width: 599px) {
      padding: 12px;
    }

    .MuiAvatar-circular {
      width: 45px;
      height: 45px;
      margin-right: 9px;
      @media (max-width: 599px) {
        width: 30px;
        height: 30px;
      }
    }

    .create-comm-text {
      width: 100%;
      height: 100%;
      border-radius: $bRadius;
      background-color: #202020;
      padding: 8.5px 15px;
      font-size: 18px;
      line-height: 24px;
      font-family: $primaryFont;
      color: #b4babf;
      border: 1px solid #2e353a;
      cursor: pointer;
      @media (max-width: 599px) {
        font-size: 16px;
        padding: 4px 10px;
      }
    }
  }
}

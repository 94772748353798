@import 'src/assets/scss/variable.scss';

.activity-record-cover-sec {
    position: relative;
    margin-top: 4px;
    padding: 12px;
    background: $color-light-black;
    border-radius: 3px;

    .d-flex {
      display: flex;
      justify-content: space-between;
    }
    .activity-owner,
    .activity-time,
    .activity-content {
      font-size: 12px;
      line-height: 16px;
      font-family: $primaryFont;
      font-weight: 500;
      color: $color-pearl;
    }
    .activity-time {
      font-weight: 400;
    }
    .activity-type,
    .community-name {
      font-size: 16px;
      line-height: 21px;
      font-weight: 500;
      font-family:  $primaryFont;
      color: $color-white;
    }
    .community-name {
      font-weight: 400;
    }
    .activity-content-main {
      padding-top: 8px;
    }
    .activity-content {
      color: $color-white;
    }
  }

@import 'src/assets/scss/variable.scss';

.claim-user-main {
  color: $color-white;
  font-family: $primaryFont;
  .claim-user-inner {
    .MuiListItem-root {
      border-bottom: 1px solid $color-lava;
      padding: 10px;
    }
    .remove-button {
      color: $color-white;
      background: $color-blue;
      font-size: 12px;
      text-transform: unset;
      font-weight: 300;
      padding: 5px 12px;
    }
    .cu-image {
      width: 60px;
      height: 60px;
      margin-right: 10px;
    }
    .cu-status {
      color: #438ac1;
      text-transform: capitalize;
      font-weight: 500;
    }
    .d-flex {
      .MuiTypography-displayBlock {
        display: flex;
        align-items: center;
      }
    }
    .cu-name {
      font-size: 18px;
      display: block;
      font-weight: 500;
    }
  }
  .no-data-txt {
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    font-family: Roboto;
    text-align: center;
    color: $color-spanish-gray;
    margin-bottom: 7px;
  }
}
.mr-5 {
  margin-right: 10px !important;
}

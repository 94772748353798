@import 'src/assets/scss/variable.scss';

.fight-record-right-sec {
  padding: 13px;
  text-align: center;
  .fight-record-right-first-box {
    padding: 5px;
    border-bottom: $blockBorder;
    .start-time-sec {
      font-family: $primaryFont;
      font-size: 12px;
      line-height: 16px;
      color: $color-light-pearl;
      text-transform: uppercase;
    }
    .event-text-sec {
      font-family: $primaryFont;
      font-size: 16px;
      line-height: 21px;
      color: $color-white;
    }
    .fight-sides-sec {
      padding: 18px 0;
      .fight-left-side-sec {
        display: flex;
        text-align: right;
        justify-content: right;
        .left-text-side-sec {
          margin-right: 14px;
          align-self: center;
        }
      }
      .fight-right-side-sec {
        display: flex;
        text-align: left;
        justify-content: left;
        .right-text-side-sec {
          margin-left: 14px;
          align-self: center;
        }
      }

      .side-text {
        font-family: $authHeaderFont;
        font-size: 12px;
        line-height: 15px;
        color: $color-pearl;
      }
      .side-name {
        font-family: $authHeaderFont;
        font-size: 28.45px;
        line-height: 28.45px;
        color: $color-white;
      }
      .avatar-sec {
        width: 80px;
        height: 80px;
      }

      .fight-center-side-sec {
        font-family: $authHeaderFont;
        font-size: 12px;
        line-height: 15px;
        color: $color-pearl;
        align-self: center;
      }
    }
  }
  .fight-record-right-second-box {
    padding: 18px 0;
    border-bottom: $blockBorder;
  }
  .fight-record-right-third-box {
    display: flex;
    justify-content: flex-end;
    color: $color-white;
    padding: 12px;
    column-gap: 6px;
    .check-icon {
      color: $color-white;
      svg {
        vertical-align: middle;
      }
      // .MuiSvgIcon-root {
      //   width: 21px;
      //   border-radius: 50%;
      //   vertical-align: middle;
      //   height: 21px;
      //   padding: 3px;
      //   background-color: $color-check;
      // }
    }
    .text-number-icon {
      font-family: $primaryFont;
      font-size: 12px;
      line-height: 16px;
      border-radius: 50%;
      padding: 3px;
      width: 21px;
      height: 21px;
      align-self: center;
      background-color: $color-check;
    }
    .text-Endorsements {
      font-family: $primaryFont;
      font-size: 12px;
      line-height: 16px;
      align-self: center;
      padding-right: 18.5px;
    }
    .flag-icon {
      color: $color-check;
      svg {
        vertical-align: middle;
        path {
          stroke: $color-check;
        }
      }
    }
  }
}

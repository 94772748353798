@import 'src/assets/scss/variable.scss';

.left-fr-container {
  height: 100%;
  display: flex;
  flex-direction: column;

  .add-fr-header {
    padding: 47px 18px 18px 11px;
    border-bottom: $blockBorder;
    .header-btn {
      padding: 0;
      font-size: 20px;
      line-height: 25px;
      font-weight: 400;
      font-family: $authHeaderFont;
      color: $color-white;

      .MuiButton-startIcon {
        margin-right: 9px;
      }
    }
  }

  // OPTION BODY
  .froptions-body {
    height: 100%;
    padding: 18px 18px 18px 11px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .active-btn {
      path {
        stroke: $color-white !important;
      }

      background-color: $color-blue !important;
      color: $color-white !important;
    }

    .fr-stepper-box {
      .MuiList-padding {
        padding: 0;
      }
      .MuiListItem-root.Mui-selected {
        background-color: transparent;
      }
      .MuiListItem-gutters {
        padding: 0;
        pointer-events: none;
        .MuiListItemIcon-root {
          min-width: 23px;
        }
        .MuiListItemText-root {
          margin: 9px 0px;
        }
      }

      //   SEARCH FIGHT EVENT BOX
      .sfe-box {
        padding-top: 15px;
        padding-bottom: 32px;

        @media (max-width: 599px) {
          .date-birth-field {
            display: block !important;
            padding: 0px 12px !important;
            .date-field {
              padding: 4.5px 0px !important;
            }
            .age-field {
              padding: 4.5px 0px !important;
            }
          }
        }

        .es-btn {
          padding: 6.4px 0px 7.5px 0px;
          font-size: 12px;
          line-height: 18px;
          font-weight: 400;
          font-family: $primaryFont;
          color: $color-black-very-light;
          background-color: $color-white;

          path {
            stroke: $color-black-very-light;
          }
        }
      }
    }

    .next-draft-bx {
      width: 100%;
      padding-left: 7px;
      @media (max-width: 1279px) {
        margin-top: 50px;
      }
      .ndraft-btn {
        padding: 14px 15px;
        font-size: 21.33px;
        line-height: 26px;
        font-weight: 400;
        font-family: $authHeaderFont;
        color: $color-black-very-light;
        background-color: $color-white;
        border-radius: $bRadius;
      }

      .back-btn {
        margin-right: 14px;
        margin-bottom: 16px;
        text-transform: uppercase;
      }

      .next-btn {
        margin-bottom: 16px;
        text-transform: uppercase;
      }
    }
  }
}

@import 'src/assets/scss/variable.scss';

.cffc-asset-block {
  position: relative;
  .MuiListItem-gutters {
    padding-left: 0;
    padding-right: 0;
  }
  .MuiListItemAvatar-root {
    min-width: 0;
    .MuiAvatar-root {
      width: 30px;
      height: 30px;
      margin-right: 12px;
    }
  }

  .MuiTypography-body2 {
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    font-family: $primaryFont;
    color: $color-white;
  }
}

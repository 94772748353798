@import 'src/assets/scss/variable.scss';

.myorg-left,
.org-mobile-page {
  position: relative;
  width: 100%;

  .cover-photo-container {
    .MuiButtonBase-root {
      width: 100%;
      padding: 0;
    }

    .preview-cover {
      position: relative;
      width: 100%;
      height: 308px;
      border-radius: $bRadius;

      @media (max-width: 599px) {
        height: 150px !important; // addmin has common class
      }
    }
    .camera-icon {
      position: absolute;
      top: 12px;
      right: 12px;
    }

    .upload-button {
      width: 100%;
      border-radius: $bRadius;
      padding: 146px 0px;
      font-size: 12px;
      line-height: 16px;
      font-weight: 400;
      font-family: $primaryFont;
      text-transform: capitalize;
      background-color: #1b1b1b;
      border: $blockBorder;
      color: #9d9d9d;
    }

    .MuiButton-endIcon {
      position: absolute;
      top: 12px;
      right: 12px;
    }
  }

  .about-tab {
    .about_content {
      font-family: $primaryFont;
      font-size: 12px !important;
      line-height: 18px !important;
      padding-top: 10px;
    }
  }
  .organisation-create-post {
    margin-top: 9px;
  }
  .profile-type-slider {
    position: relative;
    margin-top: 9px;

    .MuiIconButton-root {
      padding: 0;
      @media (max-width: 599px) {
        font-size: 1rem !important; // addmin has common class
      }
    }
    .no-info-txt {
      font-size: 12px;
      line-height: 18px;
      font-weight: 400;
      font-family: $primaryFont;
      color: $color-spanish-gray;
      margin-top: 16px;
      margin-bottom: 7px;
      text-align: center;
    }
    .title-block {
      display: flex;
      justify-content: space-between;

      .tab-block-title {
        width: 100%;
        display: flex;
        justify-content: space-between;
        font-size: 21.33px;
        line-height: 26px;
        font-family: $authHeaderFont;
        color: $color-white;
        @media (max-width: 599px) {
          font-size: 14px !important; // addmin has common class
          line-height: 20px !important; // addmin has common class
        }
      }
    }
  }
}

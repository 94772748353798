@import 'src/assets/scss/variable.scss';

.connection-page {
  .cp-txt {
    font-size: 16px;
    line-height: 21px;
    font-weight: 400;
    font-family: $authHeaderFont;
    color: $color-white;
    margin-bottom: 18px;
  }
}

@import 'src/assets/scss/variable.scss';

.upload-btn {
    padding: 10px;
    height: 35px;
    font-size: 20px;
    line-height: 26px;
    font-family: $authHeaderFont;
    background-color: $color-blue !important;
    color: $color-white !important;

    &:hover {
        background-color: $color-blue;
        color: $color-white;
    }

    &:disabled {
        background-color: $color-fb;
    }
}
@import 'src/assets/scss/variable.scss';

.welcome-box {
  padding: 18px;
  background: $blockColor;
  border: $blockBorder;
  border-radius: $bRadius;
  margin-bottom: 9px;

  .welcome-title {
    text-transform: uppercase;
    font-size: 16px;
    line-height: 20px;
    font-family: $authHeaderFont;
    color: $color-white;
    margin-bottom: 4px;
  }
  .welcome-text {
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    font-family: $primaryFont;
    color: $color-white;
  }
  .edit-welcome-btn {
    padding: 0;
    height: 30px;
    border-radius: $bRadius;
    color: $color-white;
    background-color: $color-blue;
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    font-family: $primaryFont;
    text-transform: capitalize;
    margin-top: 19px;
    &:hover {
      color: $color-white;
      background-color: $color-blue;
    }
  }
  .edit-welcome-btn.Mui-disabled{
    background-color: $color-blue;
    color: $color-white;
  }

  .no-welcome-txt {
    line-height: 16px;
    text-align: center;
    color: $color-spanish-gray;
    font-size: 12px;
    font-family: $primaryFont;
    font-weight: 400;
    margin-top: 14px;
    margin-bottom: 7px;
  }
}

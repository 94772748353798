.table-container {
    .MuiPaper-root{
        background-color: black !important;
    }
    margin-top: 70px;
    max-width: 80%;
    min-width: 300px;
    margin: 70px auto;
}

@media screen and (max-width: 1024px) {
    .MuiPaper-root{
        background-color: black !important;
    }
    .table-container {
        max-width: 90%;
        min-width: 300px;
        margin: 135px auto;
    }

}
.iconButton {
  padding: 0 !important;
  display: flex;
  align-items: flex-end;
}

.cancelButton {
  margin-left: 8px;
}

.percent {
  margin: 8px;
}
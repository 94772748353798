@import 'src/assets/scss/variable.scss';

.logo-anchor {
  display: flex;
  align-items: center;
}

.layout-wrapper {
  @media (max-width: 1279px) {
    background-color: #202020;
  }
}

.app-bar, .admin-app-bar {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  right: initial !important;
  bottom: initial !important;
  padding-right: 0 !important;
}

.app-bar {
  :global(.MuiToolbar-regular) {
    min-height: 54px;
    @media (min-width: 600px) {
      min-height: 54px;
    }
  }

  .app-menu-btn {
    text-transform: uppercase;
    position: relative;
    margin-left: 50px;

    @media (min-width: $mobileBreakPoint) and (max-width: 1100px) {
      margin-right: 0;
      margin-left: 20px;
    }

    @media (min-width: 1600px) {
      margin-left: 100px;
    }
    @media (min-width: 1860px) {
      margin-left: 129px;
    }
    &:hover {
      color: $color-blue;
    }
  }

  .app-bar-cm-user {
    position: relative;
    margin-left: 15px;
    // @media (max-width: 1279px) {
    //   display: none;
    // }

    .cm-user-title {
      text-transform: uppercase;
      font-size: 21px;
      line-height: 23px;
      font-family: $authHeaderFont;
      color: $color-white;
      display: flex;
      align-items: center;
      span {
        color: $color-blue;
      }
    }
  }

  .app-bar-searchbtn {
    position: relative;
    padding: 5px;
    margin-right: 17px;
    margin-left: 50px;
    @media (min-width: 1860px) {
      margin-left: 200px;
    }
  }

  .app-bar-notification-btn {
    position: relative;
    padding: 5px;
    margin-right: 12px;
    //HIDE-MVP: CM-78
    //added to hide search
    margin-left: 96px;
    @media (min-width: 1860px) {
      margin-left: 246px;
    }
    //
  }

  .app-bar-notification-btn.message-icon {
    //HIDE-MVP: CM-78
    //added to hide search
    margin-left: 0;
    //
    .msg-icon {
      width: 25px;
      height: 25px;
    }
  }
}

@import 'src/assets/scss/variable.scss';

.search-page {
  .search-title-box {
    width: 100%;
    padding-bottom: 18px;
    .search-title-txt {
      font-size: 37.92px;
      line-height: 47px;
      font-family: $authHeaderFont;
      color: $color-white;
      text-align: center;
    }
  }
}

@import 'src/assets/scss/variable.scss';

.newsfeed-community-post {
  margin-top: 9px;
  background-color: $blockColor;
  border: $blockBorder;
  border-radius: $bRadius;
  .fill-blue {
    path {
      fill: $color-blue;
    }
  }
  .fill-red {
    path {
      fill: #c01515;
    }
  }
  .post-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 18px 18px 19px 18px;
    @media (max-width: 599px) {
      padding: 12px;
    }
    // HEADER LEFT
    .header-left {
      width: 70%;
      .user-box {
        display: flex;

        .user-img {
          width: 45px;
          height: 45px;
        }
        .name-time {
          margin-left: 15px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 100%;
          .user-name-verification-sec{
            display: flex;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 90%;
            svg{
              align-self: center;
            }
          }
          .user-name {
            font-size: 16px;
            line-height: 21px;
            font-weight: 400;
            font-family: $primaryFont;
            color: $color-white;
            cursor: pointer;
            padding-right: 12px;
          }
          .post-time {
            font-size: 12px;
            line-height: 18px;
            font-weight: 400;
            font-family: $primaryFont;
            color: #6a6a6a;
          }
        }
      }
    }

    // HEADER RIGHT
    .header-right {
      text-align: right;
      .stastics-box {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .statics-val {
          font-size: 12px;
          line-height: 18px;
          font-weight: 400;
          font-family: $primaryFont;
          color: #b4babf;
        }

        .up-val {
          margin-right: 13px;
        }
        @media (max-width: 399px) {
          .MuiIconButton-root {
            padding: 12px 0;
          }
        }
      }
      .badges-box {
        // display: flex;
        margin-top: 9px;
        justify-content: flex-end;
        cursor: pointer;
        .badge-avatar {
          height: 20px;
          width: 20px;
          background: $color-white;
          border-radius: 50px;
          margin-left: 4px;
          display: inline-flex;
        }
        .badge-avatar:last-child {
          margin-right: 0;
        }
      }
    }
  }
  //UPDATED SECTION
  .updated-sec {
    .updated-text {
      font-size: 16px;
      line-height: 21px;
      text-align: center;
      font-family: $primaryFont;
      color: $color-white;
    }
    .endorse-text {
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      font-family: $primaryFont;
      color: $color-white;
    }
    .updated-data-sec {
      padding: 18px 15px;
    }
  }
  .event-fight-post {
    background-color: $color-light-black;
    padding: 18px;
    margin: 18px 15px;
    .event-title {
      font-size: 12px;
      line-height: 16px;
      font-weight: 400;
      font-family: $primaryFont;
      color: $color-light-pearl;
    }
    .event-name {
      font-size: 16px;
      line-height: 21px;
      font-weight: 400;
      font-family: $primaryFont;
      color: $color-white;
    }
    .event-fight-record-data {
      padding-top: 23px;
      // display: flex;
      // justify-content: space-between;
      .v-sec{
        .v-text{
            background: $color-light-black;
        }
    }
      .event-profile-data {
        display: flex;
        .event-fight-profile-sec {
          position: relative;
          .event-fight-profile {
            width: 50px;
            height: 50px;
          }
          .correct-icon {
            position: absolute;
            right: 0;
            bottom: 0;
            padding: 0;
            svg {
              width: 18px;
              height: 18px;
            }
          }
        }
        .event-fight-name {
          font-size: 21.33px;
          line-height: 28.45px;
          font-family: $authHeaderFont;
        }
      }
      .win-text {
        font-size: 16px;
        line-height: 18px;
        font-weight: 400;
        font-family: $primaryFont;
        color: $color-white;
        margin-top: 9px;
      }
      .event-fight-left {
        width: 40%;
        text-align: left;
        .event-profile-data {
          .event-fight-name {
            margin-left: 9px;
            align-self: center;
          }
        }
      }
      .event-fight-center {
        width: 20%;
        text-align: center;
        font-size: 12px;
        line-height: 15px;
        font-family: $authHeaderFont;
        color: $color-pearl;
        text-transform: uppercase;
        margin-top: 18px;
      }
      .event-fight-right {
        width: 40%;
        .event-profile-data {
          justify-content: flex-end;
          .event-fight-name {
            margin-right: 9px;
            align-self: center;
          }
        }
      }
    }
  }

  // POST CONTENT
  .post-content {
    position: relative;
    padding: 0px 18px 17px 18px;
    @media (max-width: 599px) {
      padding: 0px 12px 12px 12px;
    }
    // CONTENT TEXT
    .content-text {
      position: relative;
      font-size: 16px;
      line-height: 20.8px;
      font-weight: 400;
      font-family: $primaryFont;
      color: $color-white;
      padding-bottom: 17px;
      img {
        width: 100% !important;
      }
      @media (max-width: 599px) {
        font-size: 14px;
        line-height: 20px;
        padding-bottom: 0px;
        text-align: justify;
      }
    }

    // CONTENT IMAGE
    .content-img {
      width: 100%;
      height: auto;
    }
  }

  // POST ACTIONS
  .post-actions {
    border-top: $blockBorder;
    padding: 14px 18px 19px 18px;
    @media (max-width: 599px) {
      padding: 12px;
    }
    .post-act-btns {
      display: flex;
      justify-content: space-around;
      align-items: center;
      @media (max-width: 599px) {
        justify-content: flex-start;
      }
      .MuiGrid-item {
        @media (max-width: 599px) {
          min-width: 50%;
        }
      }

      .act-btn {
        padding: 0;
        min-width: 0;
        background-color: transparent;
        font-size: 12px;
        line-height: 18px;
        font-weight: 400;
        font-family: $primaryFont;
        color: #b4babf;
        text-transform: capitalize;

        .MuiTouchRipple-root {
          display: none;
        }
        .MuiButton-startIcon {
          margin-right: 5.4px;
        }
      }
    }
  }

  // ADMIN BUTTON
  .admin-box {
    margin-top: 9px;
    .admin-btn {
      width: 100%;
      padding: 0;
      height: 30px;
      font-size: 12px;
      line-height: 18px;
      font-weight: 400;
      font-family: $primaryFont;
      color: $color-light-blue;
      background-color: $color-blue;
      text-transform: capitalize;

      .MuiButton-endIcon {
        margin-left: 6.1px;
      }
    }
  }
}

@import 'src/assets/scss/variable.scss';

.comment-box {
  // CREATE COMMENT BOX
  .see-all-cmt-btn {
    color: $color-white;
  }
  .create-cmt-bx {
    padding: 18px;
    background-color: $blockColor;
    border: $blockBorder;
    border-radius: $bRadius;

    .user-box {
      display: flex;
      align-items: center;
      .user-image {
        width: 45px;
        height: 45px;
        margin-right: 9px;
      }
      .user-name {
        font-size: 16px;
        line-height: 21px;
        font-weight: 400;
        font-family: $primaryFont;
        color: $color-white;
        cursor: pointer;
      }
    }
    .cmt-form {
      margin-top: 28px;
      color: $color-black;
      .field-character-count {
        float: right;
        margin-right: -14px;
        font-size: 12px;
        line-height: 18px;
        font-weight: 400;
        font-family: $primaryFont;
        color: $color-white;
      }

      .comment-btnbox {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        .cmt-btn {
          padding: 4px 16px;
          min-width: 0;
          font-size: 18px;
          line-height: 22px;
          font-weight: 400;
          font-family: $authHeaderFont;
          color: $color-light-blue;
          background-color: $color-blue;
        }
      }
    }
  }

  .add-reply-bx {
    margin-top: 18px;
    padding-left: 45px;
    .add-cmt-reply {
      cursor: pointer;
    }
  }

  .thread-comments {
    margin-top: 18px;
    padding-left: 45px;

    .comment-btnbox {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      .cmt-btn {
        padding: 4px 16px;
        min-width: 0;
        font-size: 18px;
        line-height: 22px;
        font-weight: 400;
        font-family: $authHeaderFont;
        color: $color-light-blue;
        background-color: $color-blue;
      }
    }
  }

  .yhno-info-txt {
    font-size: 16px;
    line-height: 18px;
    font-weight: 400;
    font-family: $primaryFont;
    color: #9d9d9d;
    text-align: center;
    padding: 24px 0px;
  }
}

// comment box css start
.comment-list-box-main {
  padding: 18px;
  margin-top: 9px;
  background-color: $blockColor;
  border: $blockBorder;
  border-radius: $bRadius;
  // overflow-x: scroll;
  &::-webkit-scrollbar {
    height: 6px;
  }
  &::-webkit-scrollbar-track {
    background-color: $color-black-very-light;
    border-radius: $bRadius;
  }
  &::-webkit-scrollbar-thumb {
    background: $color-blue;
    border-radius: $bRadius;
  }
  .continue-thread {
    color: $color-blue;
    text-transform: unset;
  }
  .readmore-btn {
    padding: 0;
    font-size: 16px;
    line-height: 18px;
    font-weight: 400;
    font-family: Roboto;
    text-transform: inherit;
    color: #438ac1;
    display: block;
  }
  .ml-45 {
    margin-left: 45px;
  }
  .fill-blue {
    path {
      fill: $color-blue;
    }
  }
  .fill-red {
    path {
      fill: #c01515;
    }
  }
  .ml-55 {
    margin-left: 55px;
    @media (max-width: 599px) {
      margin-left: 20px;
    }
  }
  // .comment-list-box:first-child {
  //   margin-top: 0;
  // }

  .comment-list-box {
    color: $color-white;
    font-family: Roboto;
    // margin-top: 30px;
    margin: 15px 0;
    .user-box {
      width: 100%;
      display: flex;
      margin-bottom: 14px;
    }
    .user-box {
      .name-time {
        width: 100%;
        margin-left: 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .user-name {
          display: flex;
          justify-content: space-between;
          font-size: 16px;
          line-height: 21px;
          cursor: pointer;
          font-weight: 400;
          @media (max-width: 359px) {
            font-size: 14px;
          }

          .cmt-actionbtn {
            padding: 0;
          }
        }
        .post-time {
          font-size: 12px;
          line-height: 18px;
          font-weight: 400;
          color: #6a6a6a;
        }
        .badges-box {
          // display: flex;
          // margin-top: 9px;
          // justify-content: end;
          cursor: pointer;
          // display: flex;
          .badge-avatar {
            height: 20px;
            width: 20px;
            background: $color-white;
            border-radius: 50px;
            margin-left: 4px;
            display: inline-flex;
          }
          .badge-avatar:last-child {
            margin-right: 0;
          }
        }
      }
    }
    .user-comments {
      .cmt-hdln {
        font-size: 16px;
        line-height: 21px;
        word-break: break-word;
        a {
          color: $color-blue;
        }
        @media (max-width: 359px) {
          font-size: 14px;
        }
      }
    }
    .header-right {
      margin-top: 9px;

      .stastics-box {
        display: flex;
        align-items: center;
        .MuiIconButton-root {
          padding: 0;
        }
        .statics-val {
          font-size: 12px;
          line-height: 18px;
          font-weight: 400;
          font-family: $primaryFont;
          color: #b4babf;
        }
        .MuiBox-root {
          margin-right: 9px;
          svg {
            margin-right: 4px;
          }
        }
      }
    }
    .users-reply {
      border: none;
      margin-left: 45px;
      @media (max-width: 599px) {
        margin-left: 30px;
      }
    }
  }
  .add-cmt-reply {
    position: relative;
    margin-top: 18px;
    .comment-btnbox {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      .cmt-btn {
        padding: 4px 16px;
        min-width: 0;
        font-size: 18px;
        line-height: 22px;
        font-weight: 400;
        font-family: $authHeaderFont;
        color: $color-light-blue;
        background-color: $color-blue;
      }
    }
  }
}
.tag-users-list {
  color: $color-white;
  height: 100px;
  overflow-y: auto;
  background: #202020;
  padding: 0 25px;
  margin-top: 10px;

  .MuiListItem-gutters {
    padding: 0;
  }
}
.tag-users-list::-webkit-scrollbar {
  display: none;
}
.reaction-popover {
  .MuiPopover-paper {
    border-radius: 37px;
    padding: 5px 10px;
  }
  .react-icon {
    padding: 3px;
    .reaction-img {
      width: 25px;
      height: 25px;
    }
  }
  .react-selected {
    border: $blockBorder;
  }
}
.reaction-dialog-box {
  .MuiDialog-paper {
    min-width: 812px;
    @media (max-width: 959px) {
      max-width: 100%;
      min-width: 0;
    }
  }
}
// end
.notification-comment {
  background-color: $color-blue;
}
.create-reply-bx {
  margin-left: 55px;
  .create-cmt-bx {
    padding: 0;
    border: 0;
    .user-box {
      display: none;
    }
  }
}

@import 'src/assets/scss/variable.scss';
.recent-activity-container {
  position: relative;
  padding: 12px;
  margin-top: 4px;
  min-height: 102px;
  background: #202020;
  cursor: pointer;
  .activity-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 9px;

    .activity-type-name {
      position: relative;
      .activity-type-txt {
        font-size: 16px;
        line-height: 21px;
        font-weight: 500;
        color: $color-white;
        font-family: $primaryFont;
      }
      .community-name-txt {
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        font-family: $primaryFont;
        color: $color-white;
      }
    }

    .activity-owner-time {
      position: relative;

      .activity-owner-time-txt {
        font-size: 12px;
        line-height: 16px;
        font-family: $primaryFont;
        font-weight: 500;
        color: #6a6a6a;
        text-align: end;
      }
    }
  }

  .activity-body-txt {
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    font-family: $primaryFont;
    color: $color-white;
    word-break: break-word;
    a {
      color: $color-blue;
    }
  }
}

@import 'src/assets/scss/variable.scss';

// EVENT DISCOVER AND MY EVENTS
.events-left-side-block {
  padding: 47px 10px 18px 10px;
  .basic-event-left-side {
    .block-title {
      font-size: 37.92px;
      line-height: 47px;
      font-family: $authHeaderFont;
      color: $color-white;
      // margin-bottom: 20px;
    }

    // SEARCH FIELD
    .search-box {
      position: relative;
      .MuiInputBase-root {
        background-color: #1b1b1b;
      }
      .MuiOutlinedInput-input {
        font-size: 16px;
        line-height: 21px;
        font-weight: 400;
        font-family: $primaryFont;
        color: $color-white;
      }
      .MuiOutlinedInput-adornedStart {
        padding-left: 7px;
      }
      .MuiOutlinedInput-inputMarginDense {
        padding-top: 8.5px;
        padding-bottom: 8.5px;
      }
      svg {
        width: 22px;
        height: 22px;
      }
    }

    // CREATE EVENT BUTTON
    .create-event-btn {
      margin-top: 7px;
      padding: 0;
      height: 30px;
      background-color: $color-blue;
      color: $color-light-blue;
      font-size: 12px;
      line-height: 18px;
      font-weight: 400;
      font-family: $primaryFont;
      text-transform: capitalize;
    }
    .event-check-box-sec{
      padding: 27px 0;
    }
    // DISCOVER - YOUR EVENTS BUTTON
    .dy-events-btns {
      padding: 30px 23px;

      .MuiButton-label {
        justify-content: flex-start;
      }
      .discover-btn {
        margin-bottom: 14px;
      }

      .active {
        color: $color-blue !important;
      }

      .dy-btn {
        padding: 0;
        min-width: 0;
        font-size: 16px;
        line-height: 21px;
        font-weight: 400;
        font-family: $primaryFont;
        color: $color-white;
        text-transform: capitalize;
      }
    }
  }
}

// CREATE EVENT BOX
.events-left-side-create {
  padding-top: 47px;
  padding-bottom: 18px;

  .new-event-box-head {
    display: flex;
    align-items: center;
    padding: 0px 11px 18px 11px;
    border-bottom: $blockBorder;
    justify-content: space-between;
    .new-event-title{
      display: flex;
    }
    .MuiIconButton-root {
      padding: 0px;
      margin-right: 9px;
    }

    .box-title {
      font-size: 20px;
      line-height: 25px;
      font-weight: 400;
      font-family: $authHeaderFont;
      color: $color-white;
    }
  }
  .event-type-box{
    padding: 18px 11px;
    display: flex;
    .event-type{
      font-size: 21.33px;
      line-height: 27px;
      color: $color-white;
      font-family: $primaryFont;
    }
    .event-type-blue{
      color: $color-blue;
      padding-left: 5px;
    }
  }

  .create-es-bx {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .event-stepper-box {
      padding: 0px 11px 18px 11px;
      .MuiList-padding {
        padding: 0;
      }
      .MuiListItem-root.Mui-selected {
        background-color: transparent;
      }
      .cursor-text{
        cursor: default;
      }
      .MuiListItem-gutters {
        padding: 0;
        .MuiListItemIcon-root {
          min-width: 23px;
        }
        .MuiListItemText-root {
          margin: 9px 0px;
        }
      }
      .add-fight-sec{
        padding-left: 23px;
        position: relative;
        .add-fight-text{
          cursor: pointer;
          font-size: 16px;
          line-height: 21px;
          font-weight: 400;
          font-family: $primaryFont;
          color: $color-blue;
          text-decoration: underline;
        }
        .fight-list-sec{
          position: initial;
          .fight-left-sec{
            background-color: $color-lime-black;
            margin: 5px 0;
            padding: 0px 10px;
            position: initial;
            .active-fight-button{
              position: absolute;
              left: 0;
            }
          }
        }

      }
    }

    .next-draft-bx {
      width: 100%;
      position: absolute;
      bottom: 0;
      padding: 18px;
      .ndraft-btn {
        padding: 14px 15px;
        font-size: 21.33px;
        line-height: 26px;
        font-weight: 400;
        font-family: $authHeaderFont;
        color: $color-black-very-light;
        background-color: $color-white;
        border-radius: $bRadius;
      }
      @media (max-width: 1279px) {
        position: initial;
      }

      .next-btn {
        margin-bottom: 16px;
        background-color: $color-blue;
        color: $color-white;
      }
      .back-btn{
        margin-bottom: 16px;
        margin-right: 5px;
      }
      .create-event-card-btn-sec{
        margin-bottom: 16px;
        display: flex;
        justify-content: center;
        .create-event-card-btn{
          border-radius: 50%;
          color: $color-white;
          padding: 0px 8px;
          margin: 0 10px;
          min-width: 0;
        }
        .active-btn{
          border: 1px solid $color-white;
        }
      }
    }
  }
}

@import 'src/assets/scss/variable.scss';

.admin-data-tables {
  position: relative;
  margin-top: 50px;

  .MuiTableHead-root {
    background-color: $blockColor;
    font-size: 16px;
    line-height: 21px;
    font-weight: 400;
    font-family: $primaryFont;
  }

  .MuiTableBody-root {
    margin-top: 9px;
  }

  .MuiTableCell-root {
    padding: 9px;
    color: $color-white;
    border-bottom: none;

    .MuiIconButton-root {
      padding: 0px 12px;
    }
  }

  .MuiButton-outlined {
    border-color: $color-blue;
    color: $color-blue;
  }
}

.list-btn {
  svg {
    path {
      stroke: $color-blue;
    }
  }
}

.lu-btn {
  svg {
    fill: #438ac1;
    height: 18px;
  }
}

.verify-user {
  color: $color-white;
  text-align: center;

  .modal-heading {
    font-size: 30px;
    padding-bottom: 15px;
  }

  .MuiButton-contained {
    background: $color-blue;
    margin: 0 10px;
  }

  .MuiButton-outlined {
    border-color: $color-blue;
    color: $color-blue;
    margin: 0 10px;
  }
}

@import 'src/assets/scss/variable.scss';

.share-main {
  .head-main {
    font-size: 28.45px;
    line-height: 36px;
    font-family: $authHeaderFont;
    color: $color-white;
    margin-bottom: 12px;
  }
  .sub-head {
    font-size: 16px;
    font-family: $authHeaderFont;
    color: $color-white;
    line-height: 20px;
  }
  .sub-content {
    margin-top: -10px;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 12px;
    color: $color-pearl;
    display: flex;
    .manage-code-link {
      color: $color-pearl;
      text-decoration: underline;
    }
  }
}
.codes-table {
  .MuiTableHead-root {
    background: #2e353a;
    color: $color-pearl;
  }

  .MuiTableCell-head {
    color: $color-pearl !important;
    font-size: 12px !important;
    line-height: 16px;
    font-family: $primaryFont;
    padding: 8px 9px !important;
    font-weight: 400;
    text-transform: uppercase;
    &:first-child {
      padding: 8px 8px 8px 35px !important;
    }
  }
  .MuiTableCell-root,
  .MuiFormControlLabel-label {
    color: $color-white;
    font-size: 13px;
    padding: 8px 9px;
    border: 0;
  }
  .MuiTableRow-root {
    border-bottom: $blockBorder;
  }
  .share-input-block {
    margin-top: 17px;
    display: flex;
    .MuiOutlinedInput-inputMarginDense {
      padding: 7px 10px !important;
      font-size: 12px;
      line-height: 16px;
      height: auto;
    }
    .copy-button {
      background: $color-blue;
      color: $color-white;
      font-size: 12px;
      line-height: 16px;
      padding: 7px 15.5px;
      min-width: 82px;
      .MuiButton-label {
        column-gap: 3px;
      }
    }
  }
  .social-icon-block {
    margin-top: 17px;
    display: flex;
    column-gap: 13px;
    .social-icon {
      height: 36px;
      width: 36px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
    .fb {
      background: $color-fb;
    }
    .lin {
      background: $color-twitter;
    }
    .tw {
      background: $color-twitter;
    }
    .pin {
      background: $color-pin;
    }
    .wt {
      background: $color-wp;
    }
  }
}

@import 'src/assets/scss/variable.scss';

.selected-about-types {
  position: relative;

  .MuiList-padding {
    padding: 0;
    margin-bottom: -18px;
  }
  .MuiListItemIcon-root {
    min-width: 0;
    margin-right: 9px;
  }

  .MuiListItem-gutters,
  .MuiListItem-dense {
    padding: 0;
  }

  .MuiTouchRipple-root {
    display: none;
  }

  .MuiListItem-button:hover,
  .MuiIconButton-colorSecondary:hover,
  .MuiCheckbox-colorSecondary.Mui-checked:hover {
    background-color: transparent;
  }

  .MuiListItemText-primary {
    font-size: 16px;
    line-height: 21px;
    font-weight: 400;
    font-family: $primaryFont;
    color: $color-white;
  }
}

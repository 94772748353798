@import 'src/assets/scss/variable.scss';

.event-invite-sec{
    background-color: $blockColor;
    border: $blockBorder;
    border-radius: $bRadius;
    padding: 18px;
    min-height: 863px;
    height: 100%;
    .invite-title{
        font-size: 37.92px;
        line-height: 47px;
        font-weight: 400;
        font-family: $authHeaderFont;
        color: $color-white;
    }
    .invite-box-sec{
        padding-top: 29px;
        position: relative;
        .invite-sub-title{
            font-size: 21.33px;
            line-height: 26px;
        }
        .MuiCheckbox-root{
          color: $color-white;
        }
        .Mui-disabled{
          opacity: 0.3;
        }
    }
    .MuiListItem-root{
        padding: 0;
        font-size: 16px;
        line-height: 21px;
        font-weight: 400;
        font-family: $primaryFont;
        .MuiListItemIcon-root{
          min-width:25px;
        }
        .MuiButtonBase-root{
            padding: 5px;
        }
    }
    .pb-0{
        padding-bottom: 0 !important;
    }
    .user-box{
      .user-admin-sec{
        padding: 7px 9px;
        background: $color-msg-left;
        border-radius: 3px;
        margin: 9px 0;
        display: flex;
        justify-content: space-between;
        .user-name-title{
          font-size: 14px;
          line-height: 19px;
          font-weight: 400;
          font-family: $primaryFont;
          color: $color-spanish-gray;
          text-transform: uppercase;

        }
        .admin-text{
          padding-right: 22px;
        }
      }
      .users-data{
            display: flex;
            justify-content: space-between;
            padding: 10px;
            border-bottom: $blockBorder;
            .user-data-sec{
                display: flex;
                .user-profile{
                    width: 30px;
                    height: 30px;
                }
               .user-name{
                font-size: 16px;
                line-height: 21px;
                font-family: $primaryFont;
                color: $color-white;
                padding-left: 18px;
                align-self: center;
                }

            }
            .user-delete-icon{
                padding: 0;
                .MuiButtonBase-root{
                  padding:0px 5px 0px 0px;
                }
            }
          }
          .selected-user-sec{
            max-height: 200px;
            overflow-x: hidden;
            overflow-y: scroll;
            &::-webkit-scrollbar {
              width: 6px;
            }
            &::-webkit-scrollbar-track {
              background-color: $color-black-very-light;
              border-radius: $bRadius;
            }
            &::-webkit-scrollbar-thumb {
              background: $color-blue;
              border-radius: $bRadius;
            }
          }
      }
      .search-field-box {
        // position: relative;
        padding: 0;
        padding-bottom:18px;
        background: #171717;
        border-radius: 3px;
        border: 0;
    }
      .filter-box {
        background-color: $blockColor;
        border: $blockBorder;
        border-radius: $bRadius;
        padding: 10px;
        margin: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media (max-width: 599px) {
          padding: 12px;
        }
        .filter-title {
          font-size: 16px;
          line-height: 20px;
          font-family: $authHeaderFont;
          color: $color-white;
        }
        .filter-btn {
          background: $color-blue;
          color: $color-white;
          font-size: 12px;
          text-transform: unset;
          padding: 5px 15px;
          margin-left: 9px;
          @media (max-width: 480px) {
            margin-bottom: 15px;
          }
        }
        .filter-tags {
          display: flex;
          align-items: center;
          width: 75%;
          .filter-options {
            @media (max-width: 480px) {
              padding-left: 15px;
            }
          }

        }
    }
    .search-adminlist-sec{
      position: absolute;
      top:154px;
    }
}

@import 'src/assets/scss/variable.scss';

.my-profile-page {
  position: relative;

  .cmng-cntr-txt {
    text-align: center;
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    font-family: $primaryFont;
    color: $color-spanish-gray;
  }

  .user-not-found-text {
    margin-top: 3px;
    font-size: 37.92px;
    line-height: 47px;
    color: $color-white;
    font-family: $authHeaderFont;
    text-align: center;
    margin-bottom: 19.5px;
  }

  .myprofile-grid-container {
    position: relative;
    display: flex;
    justify-content: space-between;
    // @media (max-width: 1279px) {
    //   display: block;
    // }

    .myprofile-left {
      width: 62%;
      height: 100%;

      // @media (max-width: 1279px) {
      //   margin-top: 18px;
      //   width: 100%;
      // }
    }

    .myprofile-right {
      width: 38%;
      height: 100%;
      padding-left: 16px;

      // @media (max-width: 1279px) {
      //   width: 100%;
      //   padding-left: 0;
      // }
    }
  }

  .see-all-btn-left {
    padding: 0;
    font-size: 21.33px;
    line-height: 26px;
    color: $color-blue;
    font-family: $authHeaderFont;
    background: transparent;
    min-width: 0;
    &:hover {
      background: transparent;
    }
  }
  .see-all-btn-right {
    padding: 0;
    font-size: 16px;
    line-height: 20px;
    color: $color-blue;
    font-family: $authHeaderFont;
    background: transparent;
    min-width: 0;
    &:hover {
      background: transparent;
    }
  }
}

.address-menuitem {
  color: $color-white !important;
  background-color: transparent !important;
  border: 1px solid #f2f2f2 !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  &:hover {
    background-color: $color-white !important;
    color: $color-black !important;
  }
}



@import 'src/assets/scss/variable.scss';

.event-detail-sec {
  color: $color-white;
  margin-bottom: 9px;

  .left-side-sec {
    font-family: $primaryFont;
    font-size: 16px;
    line-height: 21px;
    text-align: right;
    text-transform: capitalize;
  }
  .center-sec {
    font-family: $primaryFont;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
  }

  .right-side-sec {
    font-family: $primaryFont;
    font-size: 16px;
    line-height: 21px;
    text-align: left;
    text-transform: capitalize;
  }
}
.event-detail-sec:first-child .center-sec {
  padding: 0 20px;
}
.event-detail-sec:last-child {
  margin-bottom: 0;
}

@import 'src/assets/scss/variable.scss';

.newsfeed-container {
  .post-not-available-text {
    margin-top: 3px;
    font-size: 37.92px;
    line-height: 47px;
    color: $color-white;
    font-family: $authHeaderFont;
    text-align: center;
    margin-bottom: 19.5px;
  }
  .newsfeed-grid-container {
    position: relative;
    display: flex;
    justify-content: space-between;
    @media (max-width: $mobileBreakPoint) {
      display: block;
    }

    .newsfeed-left {
      width: 62%;
      height: 100%;

      @media (max-width: $mobileBreakPoint) {
        margin-top: 18px;
        width: 100%;
      }
    }

    .newsfeed-right {
      width: 38%;
      height: 100%;
      padding-left: 16px;

      @media (max-width: $mobileBreakPoint) {
        width: 100%;
        padding-left: 0;
      }
    }
  }
}

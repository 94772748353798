@import 'src/assets/scss/variable.scss';


.event-fight-cover{
    background-color: $blockColor;
    border: $blockBorder;
    border-radius: $bRadius;
    padding: 18px;
    min-height: 863px;
    height: 100%;
    .event-fight-heading{
        font-size: 37.92px;
        line-height: 47px;
        font-weight: 400;
        font-family: $authHeaderFont;
        color: $color-white;
    }
    .mt-27{
        margin-top: 27px
    }
    .p-0{
        padding: 5px 0;
    }
    .event-fight-form {
        margin-top: 24px;
        margin-bottom: 42px;
        .event-titles{
          display: flex;
          padding-bottom: 0;
          padding-top: 36px;
          .event-titles-name{
            font-size: 21.33px;
            line-height: 26px;
            font-family: $primaryFont;
            font-weight: 400;
            padding-left: 15px;
            align-self: center;
          }
        }
        .event-titles:first-child{
          padding-top: 0px;
        }
        .add-date-btn-sec{
          text-align: end;
          .add-date-btn{
            background-color: $color-blue;
            color: $color-white;
            font-size: 12px;
            line-height: 18px;
            font-family: $primaryFont;
            font-weight: 400;
            text-transform: capitalize;
            &:hover {
              background-color: $color-blue;
              color: $color-white;
            }
          }
        }
        .event-bg-sec{
          width: 100%;
          padding: 20px 12px;
          background:$color-light-black;
          margin: 0 10px;
          .event-bg-grid{
            display: flex;
            justify-content: space-between;
            .date-bg-text{
              color: $color-pearl;
              font-size: 16px;
              line-height: 21px;
              font-family: $primaryFont;
            }
            .delete-date{
              padding: 0 5px;
            }
          }
        }

      }
      .event-cover-box {
        border-top: $blockBorder;
        padding-top: 18px;
    }
    .date-birth-grid{
        margin: 0px 12px;
        width: 100%;
    }
    .MuiList-padding {
     padding: 0;
     .MuiListItem-gutters {
         padding: 0;
     }
    }
    .actions-header {
      .MuiTab-wrapper {
        color: $color-white;
        opacity: 1 !important;
      }
    }
    @media (max-width: 599px) {
      .date-birth-field {
        display: block !important;
        .date-field {
          padding-right: 0px !important;
        }
        .age-field {
          padding-left:  0px !important;
          padding-top: 5px;
        }
      }
    }
}

@import 'src/assets/scss/variable.scss';

.my-profile-page {
  position: relative;
  .myprofile-grid-container {
    position: relative;
    display: flex;
    justify-content: space-between;
    // @media (max-width: 1279px) {
    //   display: block;
    // }

    .myorg-left {
      width: 62%;
      height: 100%;

      // @media (max-width: 1279px) {
      //   margin-top: 18px;
      //   width: 100%;
      // }
    }

    .myorg-right {
      width: 38%;
      height: 100%;
      padding-left: 16px;

      // @media (max-width: 1279px) {
      //   width: 100%;
      //   padding-left: 0;
      // }
    }

    .org-mobile-page {
      display: flex;
      flex-direction: column;
      .cover-photo-container {
        order:1;
        margin-left: -16px;
        margin-right:-16px;
      }
      .organisation-create-post {
        order: 2;
      }
      .my-right-top {
        order:3;
        margin-top:10px;
      }
      .badge-container {
        order:4;
      }
      .org-profile-about {
        order:5;
        .about-tab {
          margin-top: 0;
        }
      }
      .social-activity-container {
        order:6;
      }
      .org-profile-activity {
        order:7;
        margin-bottom:10px;
      }
    }
  }
  .mw-740 {
    .MuiDialog-paper {
      max-width: 812px;
    }
  }
}

.org-pageownner-dialog {
  .MuiDialog-paper {
    @media (max-width:$mobileBreakPoint){
      margin: 0%;
      position: absolute;
      top: 0;
      bottom: 0;
      min-height: calc(100% - 32px);
      width: 100%;
      padding-top: 0;
      overflow-x: hidden;
      .dialog-content-box {
        position:sticky;
        top:0;
        padding-top:10px;
        z-index: 2;
        background-color: #171717;
        width: 100%;
        margin-bottom:10px;
      }
      .dialog-submit-container {
        position: sticky;
        bottom: 0;
        background-color: #171717;
        padding-top: 0;
        margin-top: 20px;
        z-index: 2;
      }
      .dialog-submit-button {
        padding-bottom:25px;
        margin-top: 0;
      }
    }
  }
}

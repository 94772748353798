@import 'src/assets/scss/variable.scss';

.admin-container-box {
  position: relative;
  margin: 70px 0;

  .admin-page-haeding {
    text-transform: uppercase;
    margin-top: 3px;
    font-size: 37.92px;
    line-height: 47px;
    color: $color-white;
    font-family: $authHeaderFont;
    text-align: center;
    margin-bottom: 19.5px;
  }
}

@import 'src/assets/scss/variable.scss';

.fight-record-left-sec {
  padding: 18px;
  border-right: $blockBorder;
  .left-img-sec {
    width: 214px;
    height: 101px;
  }
  .left-text-sec {
    margin-top: 18px;
    .text-line-sec {
      margin-bottom: 9px;
      .text-heading-sec {
        font-family: $primaryFont;
        font-size: 12px;
        line-height: 18px;
        color: $color-pearl;
        text-transform: uppercase;
      }
      .text-data-sec {
        font-family: $primaryFont;
        font-size: 16px;
        line-height: 21px;
        color: $color-white;
      }
    }
  }
}

@import 'src/assets/scss/variable.scss';

.messagesPage-sec {
  .messages-grid-sec {
    display: grid;
    grid-template-columns: 40% 60%;
    column-gap: 16px;
  }
  .welcome-content {
    margin-top: 50px;
    word-break: break-word;
    .d-flex {
      display: flex;
      justify-content: center;
      align-items: center;
      @media (max-width: 599px) {
        display: block;
        text-align: center;
      }
    }
    .MuiAvatar-root {
      width: 150px;
      height: 150px;
      margin-right: 40px;
      @media (max-width: 599px) {
        margin: 0 auto;
      }
    }
    .fs-50 {
      font-size: 50px;
    }
    .fw-bold {
      font-weight: bold;
    }
  }
}
@media (max-width: 959px) {
  .messagesPage-sec {
    .messages-grid-sec {
      display: grid;
      grid-template-columns: 100%;
      row-gap: 16px;
    }
  }
}

@import 'src/assets/scss/variable.scss';

.menu-bar {

  background: #000;

  div {
    background: #000;
  }

  .close-button {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    :global(.MuiIconButton-root) {
      padding: 0;
      color: $color-white;
    }
  }

  // backarrow icon in the mobile nav menu page
  .back-arrow-button {
    position: relative;
    display: flex;
    align-items: center;

    :global(.MuiIconButton-root) {
      padding: 0;
      color: $color-white;
    }

    .mobile-nav-top {
      margin: auto;
    }
  }

  :global(.MuiButton-label) > span {
    margin-left: auto;
    font-size: 34px;
    letter-spacing: 0.5px;

    @media (max-width: 959px) {
      font-size: 28px;
    }
    @media (max-width: 599px) {
      font-size: 24px;
    }
  }

  :global(.MuiButton-label) {
    display: block;
    margin-left: 0 !important;
    color: #fff !important;
    font-size: 21px !important;
    font-family: $authHeaderFont !important;
    font-weight: 400 !important;
    justify-content: flex-start !important;
    letter-spacing: 0 !important;
    padding: 0 8px !important;
    text-transform: none !important;
    margin-right: 25px !important;
    text-align: left;
  }
}

.res-logout-app-btn {
  font-size: 24px !important;
  margin-left: auto !important;
  margin-right: auto !important;
  font-family: $authHeaderFont !important;
  color: $color-white !important;
  text-align: center !important;
}

@import 'src/assets/scss/variable.scss';

.stickied-post-container {
  background-color: $blockColor;
  border-radius: $bRadius;
  border: $blockBorder;
  overflow: hidden;

  .stickied-box {
    padding-top: 10px;
    padding-bottom: 18px;

    .box-header {
      display: flex;
      align-items: center;
      padding: 0 18px 9px 18px;

      .box-title {
        font-size: 18px;
        line-height: 22px;
        font-weight: 400;
        font-family: $authHeaderFont;
        color: $color-white;
        margin-left: 5px;
        text-transform: uppercase;
      }
    }

    // POST CONTAINER
    .post-conatainer {
      display: flex;
      width: 100%;
      max-height: 350px;

      // &::-webkit-scrollbar,
      // &::-webkit-scrollbar-track,
      // &::-webkit-scrollbar-thumb,
      // &::-webkit-scrollbar-thumb:hover {
      //   width: 0;
      //   height: 0;
      // }

    }

    .post-scroll {
      overflow-x: auto;
      overflow-y: hidden;

      &::-webkit-scrollbar {
        height: 6px;
      }

      &::-webkit-scrollbar-track {
        background-color: $color-black-very-light;
        border-radius: $bRadius;
      }

      &::-webkit-scrollbar-thumb {
        background: $color-blue;
        border-radius: $bRadius;
      }

    }
  }
}

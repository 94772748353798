@import 'src/assets/scss/variable.scss';

.d-block {
  display: block !important;
}
.bpdtr-box {
  .scrollable-alphatab {
    width: 100%;
    padding-top: 4px;
    .MuiTab-root {
      padding: 1.5px;
      min-height: unset;
      font-family: $primaryFont;
      font-size: 16px;
    }
    .Mui-selected {
      background: transparent;
      color: $color-blue;
    }
    @media (max-width: 599px) {
      .MuiTab-root {
        padding: 0 6px;
        min-width: 0;
      }
    }

  }
  .tab-title {
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    line-height: 20px;
    font-family: $authHeaderFont;
    color: $color-white;
  }
  .see-all-btn-right {
    padding: 0;
    font-size: 16px;
    line-height: 20px;
    color: $color-blue;
    font-family: $color-blue;
    background: transparent;
    min-width: 0;
  }
  .pagination-main {
    .Mui-selected {
      color: $color-white;
    }
  }
  .p-0 {
    padding: 0;
  }
  .comm-dir-title {
    cursor: pointer;
  }
}

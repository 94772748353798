@import 'src/assets/scss/variable.scss';

.category-sec{
    background-color: $blockColor;
    border: $blockBorder;
    border-radius: $bRadius;
    padding: 18px;
    min-height: 863px;
    height: 100%;
    .category-title{
        font-size: 37.92px;
        line-height: 47px;
        font-weight: 400;
        font-family: $authHeaderFont;
        color: $color-white;
        margin-bottom: 18px;
    }

        .color-picker-box{
            width: 100%;
            display: flex;
            padding: 12px;
            background-color: $color-lime-black;
            border: $blockBorder;
            .color-box{
                width: 20px;
                height: 20px;
                background-color: $color-white;
            }
            .color-name{
                line-height: 21px;
                font-weight: 400;
                font-size: 16px;
                font-family: $authHeaderFont;
                color: $color-white;
                padding-left: 15px;
            }


        }
    .chromePicker-parent-sec{
            position: relative;
    }
    .chromePicker-fore{
        position: absolute;
        top:70px;
        right: 0;
    }
    .chromePicker-back{
        position: absolute;
        top:70px;
        left: 0;
    }

}

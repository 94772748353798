.paginations {
  .pagination {
    li {
      background: transparent;
      border: 0;

      &:first-child {
        a {
          min-width: auto;
          min-height: auto;
          background-color: transparent;
          line-height: 15px;
          display: initial;

          span {
            font-size: 30px;
            color: #aaa;
          }
        }
      }

      min-width: 25px;
      min-height: 25px;

      a {
        // background-color: $color-bg;
        border: none;
        border-radius: 50%;
        line-height: 15px;
        min-width: 25px;
        min-height: 25px;
        padding: 0;
      }

      &:last-child {
        a {
          min-width: auto;
          min-height: auto;
          background-color: transparent;
          line-height: 15px;
          display: initial;

          span {
            font-size: 30px;
            color: #aaa;
          }
        }
      }

      &.active {
        a {
          border-top: 0 !important;
        }
      }
    }
  }
}
.pagination-main {
  p {
    font-family: Roboto;
    display: none;
  }
  img {
    height: 15px;
    cursor: pointer;
  }
  .tablePagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
  .pagination {
    display: flex;
    align-items: center;
  }
  ul {
    margin: 0;

    li {
      background: transparent;
      min-width: unset;
      border: 0;
      margin: 5px;
      .MuiPaginationItem-ellipsis {
        color: white;
      }
      .Mui-disabled {
        color: white !important;
        // background: #438ac1 !important;
      }
      button {
        padding: 0;
        height: 35px;
        width: 35px;
        margin: 0;
        min-width: 35px;
        color: white;
        // border: 1px solid #dddddd;
        border-radius: 50%;
        @media (max-width: 599px) {
          height: 25px;
          width: 25px;
          min-width: 25px;
          font-size: 12px;
          line-height: 24px;
        }
      }
      .Mui-selected {
        border: 2px solid white;
        // background: #438ac1;
      }
      // &:last-child {
      //   margin-right: 0;
      // }
    }
  }
}

@import 'src/assets/scss/variable.scss';

.dropdown-listing-main {
  padding-top: 15px;
  color: $color-white;
  .MuiListItem-gutters {
    padding: 8px 0;
    border-bottom: $blockBorder;
  }
}
.cd-off-switch-btn {
  margin-bottom: 18px;

  .selected-com-type {
    font-size: 16px;
    line-height: 18px;
    font-weight: 400;
    color: #ffffff;
    background-color: #438ac1;
    padding: 5px 15px;
    text-transform: uppercase;
    font-family: $authHeaderFont;
    margin-right: 10px;
    border-radius: 3px !important;
    &:hover {
      background-color: #438ac1;
      color: #ffffff;
    }
  }
  .other-com-type {
    font-size: 16px;
    line-height: 18px;
    padding: 5px 15px;
    font-weight: 400;
    background-color: #ffffff;
    text-transform: uppercase;
    font-family: $authHeaderFont;
    color: black;
    margin-right: 10px;
    border-radius: 3px !important;
    &:hover {
      background-color: #ffffff;
      color: black;
    }
  }
}
.addedit-dcmain {
  position: relative;
  height: 100%;
  .top-container {
    height: 85%;
    overflow-y: auto;
  }
  // .dialog-submit-button {
  //   position: absolute;
  //   bottom: 0;
  //   width: 100%;
  // }
}
.h-90vh {
  .MuiDialog-paper {
    height: 90vh;
  }
}

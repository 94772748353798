@import 'src/assets/scss/variable.scss';

.badge_banner {
  min-height: 498px;
  background-image: url('../../../assets/images/banner/badge_banner.jpg');
  display: flex;
  align-items: center;
  .combat-container {
    margin: 0 51px 0 92px !important;
    padding-bottom: 0 !important;
  }
  .banner-head {
    font-family: $authHeaderFont;
    font-size: 67.42px;
  }
}
.allbadge_block {
  position: relative;
  margin-top: 9px;
  width: 100%;
  padding: 20px 20px 80px 20px;
  background-color: $blockColor;
  border: $blockBorder;
  border-radius: $bRadius;
}

@import 'src/assets/scss/variable.scss';

.member-listing-main {
  color: $color-white;
  font-family: $primaryFont;
  .search-box {
    .MuiInputBase-input {
      color: $color-white;
    }
  }
  .restriction-box {
    margin-top: 10px;
    padding: 10px;
    .restriction-txt {
      font-size: 16px;
      line-height: 21px;
      font-weight: 400;
      font-family: $primaryFont;
      color: $color-white;
      margin-bottom: 9px;
    }
    .restriction-chips {
      display: flex;
      // flex-wrap: wrap;
      overflow-x: scroll;
      overflow-y: hidden;
      &::-webkit-scrollbar {
        display: none;
      }
      .rest-chip {
        margin: 5px;
        background-color: transparent !important;
      }
      .MuiChip-deleteIcon {
        color: $color-white;
      }
      .checked-chip {
        background-color: $color-blue !important;
        color: $color-white !important;
      }
    }
  }
  .create-btn-main {
    display: flex;
    justify-content: flex-end;
    padding: 30px 10px;
    .create-button {
      background-color: $color-white;
    }
    @media (max-width: 599px) {
      padding: 10px;
    }
  }
  .pagination-main {
    .tablePagination {
      @media (max-width: 599px) {
        margin-top: 0px;
      }
    }
  }
  .gray-background {
    background: $color-gray-dark !important;
  }
  .member-inner {
    .MuiListItem-root {
      border-bottom: 1px solid $color-lava;
      padding: 10px;
    }
    .member-btn {
      color: $color-white;
      background: $color-blue;
      font-size: 12px;
      text-transform: unset;
      font-weight: 300;
      padding: 5px 12px;
    }
    .member-image {
      width: 60px;
      height: 60px;
      margin-right: 10px;
    }
    .member-name {
      font-size: 18px;
      display: block;
      font-weight: 500;
      margin-left: 10px;
    }
  }
  .no-data-txt {
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    font-family: Roboto;
    text-align: center;
    color: $color-spanish-gray;
    margin-bottom: 7px;
  }
}

@import 'src/assets/scss/variable.scss';

.cd-box {
  padding: 18px;
  background: $blockColor;
  border: $blockBorder;
  border-radius: $bRadius;
  margin: 9px 0;
  .cd-title {
    font-size: 16px;
    line-height: 20px;
    font-family: $authHeaderFont;
    color: $color-white;
    margin-bottom: 4px;
  }
  .cat-dis-btn {
    padding: 0;
    height: 30px;
    border-radius: $bRadius;
    color: $color-white;
    background-color: $color-blue;
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    font-family: $primaryFont;
    text-transform: capitalize;
    margin-top: 19px;
    &:hover {
      color: $color-white;
      background-color: $color-blue;
    }
  }
  .cat-dis-btn.Mui-disabled{
    background-color: $color-blue;
    color: $color-white;
  }
  .no-cd-txt {
    line-height: 16px;
    text-align: center;
    color: #9d9d9d;
    font-size: 12px;
    font-family: $primaryFont;
    font-weight: 400;
    margin-top: 14px;
    margin-bottom: 7px;
  }
}
// .combat-dialog {
//   .MuiDialog-paper {
//     height: 100%;
//   }
// }
.cat-dis-listing-main {
  padding-top: 10px;
  .list-title {
    line-height: 21px;
    font-weight: 500;
  }
  .MuiListItem-root {
    padding: 0;
    margin-top: 5px;
  }
  .MuiListItemText-root {
    margin: 0;
    span {
      font-size: 14px;
    }
  }
  .pt-18 {
    padding-top: 18px;
  }
}

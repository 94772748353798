@import 'src/assets/scss/variable.scss';

.community-page {
  .community-title-box {
    h1 {
      font-size: 37.92px;
      line-height: 47px;
      font-family: $authHeaderFont;
      color: $color-white;
      margin-bottom: 18px;
      text-transform: uppercase;
      @media (max-width: 599px) {
        font-size: 26px;
        line-height: 30px;
      }
    }
  }
  .community-search-box {
    padding-top: 36px;
    .search-field-box {
      display: flex;
      .search-btn {
        padding: 0px 18px;
        height: 45px;
        background-color: $color-blue;
        color: $color-light-blue;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        margin-left: 9px;
        text-transform: unset;
        font-family: $primaryFont;
      }
      .search-filter-sec{
        padding-right: 10px;
        display: flex;
        align-items: center;
        cursor: pointer;
      }
    }
  }
}

@import 'src/assets/scss/variable.scss';

.person-details-sec {
  //   border: $blockBorder;
  border-radius: $bRadius;
  margin: 9px 0;
  background-color: $color-light-black;
  padding: 12px;
  cursor: pointer;
  .person-detail {
    display: flex;
    .avatar-sec {
      width: 45px;
      height: 45px;
      margin-right: 11px;
    }
    .details-sec {
      width: 100%;
      align-self: center;
      .name-details-sec {
        display: flex;
        justify-content: space-between;
        .name-sec {
          font-family: $primaryFont;
          font-size: 18px;
          line-height: 24px;
          color: $color-white;
          text-transform: capitalize;
        }
        .noti-msg-sec {
          display: flex;
          .notification-sec {
            background-color: $color-check;
            color: $color-white;
            font-family: $primaryFont;
            font-size: 12px;
            line-height: 18px;
            padding: 0 5.5px;
            border-radius: 50%;
            align-self: center;
          }
          .message-three-dots {
            align-self: center;
            padding: 0px 0px 0px 5px;
            .svg-three-dots {
              width: 10px;
              height: 14px;
              cursor: pointer;
              path {
                fill: $color-white;
              }
            }
          }
        }
      }
    }
    .lastseen-sec {
      font-family: $primaryFont;
      font-size: 12px;
      line-height: 18px;
      color: $color-white;
    }
  }
  // .Sponsored-Mail-text {
  //   font-family: $primaryFont;
  //   font-size: 16px;
  //   line-height: 21px;
  //   color: $color-pearl;
  //   font-weight: bold;
  //   margin: 9px 0 3px;
  // }
  .message-content-text {
    font-family: $primaryFont;
    font-size: 16px;
    line-height: 21px;
    color: $color-pearl;
    padding-left: 56px;
    word-break: break-word;
    p {
      white-space: nowrap;
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      .wysiwyg-mention,
      a {
        color: $color-blue;
        text-decoration: underline;
      }
    }
  }
}
.person-details-sec:first-child {
  margin: 5px 0;
}
.active-chat {
  background-color: $color-msg-left;
}
.unread-chat {
  border: 2px solid $color-check;
}
